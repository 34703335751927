import React, {Component} from 'react';

class ErrorBoundry extends Component {
  constructor(props) {
    super(props);
    this.state = {hasError: false}
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  
  }
  render() {
    if (this.state.hasError) {      
      // You can render any custom fallback UI
      return (
        <main className="wrap">
          <h1>Something went wrong.</h1>
        </main>
      );
    }
    return this.props.children; 
  }
}

export default ErrorBoundry;