import {createContext, useContext, useState} from 'react';

const MechContext = createContext();
const UpdateMechContext = createContext();

// Allows us to use the mech state anywhere.
export function useMech() {
  return useContext(MechContext);
}

// Allows us to update the mech state anywhere.
export function useMechUpdate() {
  return useContext(UpdateMechContext);
}

export function MechProvider({children}) {
  const [mech, setMech] = useState({});

  return (
    <MechContext.Provider value={mech}>
      <UpdateMechContext.Provider value={setMech}>
        {children}
      </UpdateMechContext.Provider>
    </MechContext.Provider>
  )
}
