import React from 'react';

import './label.scss';

const Label = ({inputName, labelText, required = false}) => {
  return (
    <label htmlFor={inputName}>{labelText}{required ? <sup>*</sup> : ''}</label>
  )
}

export default Label;