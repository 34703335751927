import React from 'react';

import './loreList.scss';

const LoreList = ({lore}) => {

  const formatLore = (key) => {
    return <li className="flex flex-col loreItem" key={key}>{lore[key].key || lore[key]}{lore[key].value ? <span>{lore[key].value}</span> : null}</li>
  }

  return (
    <>
    <h2 className="m-t-4">Lore</h2>
    {lore && <ul className="loreList">
      {Object.entries(lore).map(([key]) => {
        return formatLore(key);
      })}
      </ul>}
    </>
  )
}

export default LoreList;