import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom'

import { useProfile, useProfileUpdate } from '../../hooks/useProfile';

import Footer from '../../components/Footer';
import Jumbotron from '../../components/Jumbotron';
import ButtonLink from '../../components/ButtonLink';
import Button from '../../components/FormElements/Button';
import InputWrap from '../../components/FormElements/InputWrap';
import SkillsList from '../../components/OriginPath/SkillsList';
import ContactsList from '../../components/OriginPath/ContactsList';
import LoreList from '../../components/OriginPath/LoreList';
import Interstitial from '../../components/Interstitial';
import { ReactComponent as Chevrons } from '../../assets/chevrons.svg';

import { useAuth } from '../../utilities/auth';
import { db } from '../../services/firebase';
import pilotData from '../../data/profileData';

const PilotLanding = () => {
  const profile = useProfile();
  const updateProfile = useProfileUpdate();
  const auth = useAuth();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);

  const ref = `users/${auth.user.uid}/pilot`;

  const deleteProfileData = () => {
    db.ref(`users/${auth.user.uid}/pilot`).remove().then(() => {
      updateProfile(pilotData);
      history.push('/origin-path/home');
    });
  }

  const setProfileName = (e) => {
    let val = e.target.value;
    updateProfile(pilot => ({ ...pilot, name: val }));
  }

  const saveAndContinue = (url) => {
    db.ref(`users/${auth.user.uid}/pilot`).set(profile).then(() => {
      history.push(url);
    }).catch(error => console.log('error: ', error));
  }

  const hasProfileData = (data) => {
    return data.created
  }

  useEffect(() => {
    let mounted = true;
    db.ref(ref).on('value', (snapshot) => {
      if (mounted) {
        updateProfile(oldState => ({ ...oldState, ...snapshot.val() }));
        setIsLoading(false);
      }
    });
    return () => mounted = false;
  }, [ref, updateProfile]);

  return (
    <>
      <main className="wrap">
        <h1>Welcome, Pilot</h1>
        <section className="flex flex-no_wrap align-start space-between">
          {isLoading ? <Interstitial><p>Loading Pilot</p></Interstitial> : null}
          {!isLoading && !hasProfileData(profile) ?
            <Jumbotron title="You have not walked the path">
              <p>Complete the origin path to see your skills and abilities.</p>
              <ButtonLink className="m-t-2 primary fancy" to={'/origin-path/home'}>
                <span>Start</span>
                <span><Chevrons /></span>
              </ButtonLink>
            </Jumbotron> : null }
            {!isLoading && hasProfileData(profile) ?
            <>
              <SkillsList skills={profile.skills} />
              <aside className="loreContacts">
                <h3 className="m-b-2">Skill points: {profile.skillPoints}</h3>
                <InputWrap className="m-b-3" inputName="loadoutName" labelText="Pilot Name:" value={profile.name || ''} onChange={e => setProfileName(e)} />
                {profile?.contacts && <ContactsList contacts={profile.contacts} />}
                {profile?.lore && <LoreList lore={profile.lore} />}
              </aside>
            </> : null}
        </section>
      </main>
      {profile?.skills ?
        <Footer hideButton>
          <Button className="negative" onClick={() => deleteProfileData()}>
            Delete
          </Button>

          <Button className="primary fancy" onClick={() => saveAndContinue('/')}>
            <span>Continue</span>
            <span><Chevrons /></span>
          </Button>
        </Footer> : null
      }
    </>
  )
};

export default PilotLanding;