import React from 'react';

import {ReactComponent as One} from '../../assets/AoeSizes/sizeOne.svg';
import {ReactComponent as Two} from '../../assets/AoeSizes/sizeTwo.svg';
import {ReactComponent as Three} from '../../assets/AoeSizes/sizeThree.svg';
import {ReactComponent as Four} from '../../assets/AoeSizes/sizeFour.svg';
import {ReactComponent as Five} from '../../assets/AoeSizes/sizeFive.svg';

const AoeSize = ({size}) => {
  let Icon = null;

  switch (size) {
    case 1:
      Icon = One;
      break;
    case 2:
      Icon = Two;
      break;
    case 3:
      Icon = Three;
      break;
    case 4:
      Icon = Four;
      break;
    case 5:
      Icon = Five;
      break;
    default:
      Icon = One;
  }
  return (<Icon />);
}

export default AoeSize;