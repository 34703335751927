import {createContext, useContext, useState} from 'react';

const PilotContext = createContext();
const UpdatePilotContext = createContext();

// Allows us to use the pilot state anywhere.
export function usePilot() {
  return useContext(PilotContext);
}

// Allows us to update the pilot state anywhere.
export function usePilotUpdate() {
  return useContext(UpdatePilotContext);
}

export function PilotProvider({children}) {
  const [pilot, setPilot] = useState({});

  return (
    <PilotContext.Provider value={pilot}>
      <UpdatePilotContext.Provider value={setPilot}>
        {children}
      </UpdatePilotContext.Provider>
    </PilotContext.Provider>
  )
}
