import React from 'react';

import AoeIcon from '../../AoeIcon';

import './radio.scss'

const Radio = ({groupName, inputName, onChange, checked, value}) => {
  return (
    <div className="radio">
      <input type="radio" name={groupName} id={inputName} onChange={onChange} value={value} checked={checked} />
      <label htmlFor={inputName} title={inputName}>
        <AoeIcon iconName={inputName} />
      </label>
    </div>
  )
}

export default Radio;