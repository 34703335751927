const generateLoreOrContact = (data, pilotData) => {
  // loop thought contacts.
  data.forEach(element => {
    const index = pilotData.findIndex(i => i.key === element.key);
    // see if contact exists in pilot data
    if(index === -1) {
      // contact !exist === push
      pilotData.push(element);
    } else {
      // else increase by value
      if(!isNaN(parseInt(element.value))){
        pilotData[index].value += parseInt(element.value, 10);
      }
    }
  });
}

const generatePilot = (data, pilot, currentStep) => {
  const {skills, contact, name, next, opens, lore, desc} = data;

  if(skills && skills.length) {
    skills.map((skill) => Object.entries(skill).map(([key, value]) => pilot.skills[key] += parseInt(value), 10));
  }

  if(contact && contact.length) {
    generateLoreOrContact(contact, pilot.contacts);
  }

  if(lore && lore.length) {
    generateLoreOrContact(lore, pilot.lore);
  }

  if(desc) {
    pilot.lore.push({key: name, value: desc});
  }

  if(next) {
    pilot.options[next] = opens;
  }

  pilot = {...pilot, skills: pilot.skills, [currentStep]: name, options: {...pilot.options}, lore: pilot.lore, contacts: pilot.contacts};

  return pilot
}

export default generatePilot;