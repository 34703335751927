import React, {useState} from 'react';

import Button from '../FormElements/Button';
import {ReactComponent as VertElipses} from '../../assets/vertElipses.svg';

import './revealingMenu.scss';

const RevealingMenu = ({children}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="revealing_menu">
      <Button className="iconOnly revealing_menu-toggle" onClick={() => setIsOpen(!isOpen)}>
        <VertElipses />
      </Button>
      <aside className={`revealing_menu-wrap flex-col ${isOpen ? 'open' : ''}`}>
        {children}
      </aside>
      </div>
  )
}

export default RevealingMenu;