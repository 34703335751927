import React from 'react';

import './jumbotron.scss';

const Jumbotron = ({title = '', children}) => {
  return (
    <div className="jumbotron flex flex-col align-center justify-center">
      {title !== '' ? <h2>{title}</h2> : null}
      {children}
    </div>
  )
};

export default Jumbotron;