import React, {useEffect, useState} from 'react';
import { useHistory } from 'react-router-dom';
import {db} from '../services/firebase';

import LinkCard from '../components/LinkCard';
import NewsFeed from '../components/NewsFeed';
import LinkList from '../components/LinkList';
import AdvertWrap from '../components/AdvertWrap';

const convertNews = (data) => {
	const output = [];
	if(data) {
		const keys = Object.keys(data);
		keys.map(key => output.push(data[key]));
		return output.reverse();
	}
}

const Home = () => {
	let history = useHistory();

	const [newsFeed, setNewsFeed] = useState();
  const newsRef = '/newsFeed';

	useEffect(() => {
		db.ref(newsRef).once('value')
			.then((snapshot) => {
				setNewsFeed(snapshot.val());
			});
	}, [newsRef]);

	return (
		<main className="wrap">
			<h1>Welcome, Pilot!</h1>
			<section className="grid gap-1 three-cols">
				<div className='col-1 grid gap-1'>
					<LinkCard onClick={() => {history.push('/profile-landing')}}>
						<h2>Origin Path</h2>
					</LinkCard>
					<LinkCard onClick={() => {history.push('/pilot-landing')}}>
						<h2>Pilot</h2>
					</LinkCard>
					<LinkCard onClick={() => {history.push('/mech-landing')}}>
						<h2>A.R.K.</h2>
					</LinkCard>
				</div>
				<div className="col-2">
					<NewsFeed news={convertNews(newsFeed)} />
				</div>
				<div className="col-3">
					<LinkList />
					<AdvertWrap />
				</div>
			</section>
		</main>
	)
}

export default Home;