import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import { ProvideAuth, useAuth } from '../utilities/auth';
import { MechProvider } from '../hooks/useCurrentMech';
import { ProfileProvider } from '../hooks/useProfile';
import { PilotProvider } from '../hooks/useCurrentPilot';
import { ModalProvider } from '../hooks/useModal';

// Mech Routes
import MechLanding from '../views/Mech/MechLanding';
import BuildProtocols from '../views/Mech/BuildProtocols/BuildProtocols';
import BuildProtocolsEdit from '../views/Mech/BuildProtocols/BuildProtocolsEdit';
import ChooseClass from '../views/Mech/ChooseClass';
import Load from '../views/Mech/Load';
import PlaySheet from '../views/Mech/PlaySheet';
import ArkUnbound from '../views/Mech/ArkUnbound';

// Profile Routes
import ProfileLanding from '../views/Profile/ProfileLanding';
import OriginPath from '../views/Profile/OriginPath';

// Pilot Routes
import PilotLanding from '../views/Pilot/PilotLanding';
import PilotLoad from '../views/Pilot/PilotLoad';
import PilotChooseClass from '../views/Pilot/PilotChooseClass';
import PilotBuildProtocols from '../views/Pilot/BuildProtocols/BuildProtocols';
import PilotBuildProtocolsEdit from '../views/Pilot/BuildProtocols/BuildProtocolsEdit';
import PilotPlaySheet from '../views/Pilot/PilotPlaysheet';

import ErrorBoundry from '../components/ErrorBoundary';
import Home from '../views/Home';
import Login from '../views/Login';
import NotFound from '../views/NotFound';
import ForgotPassword from '../views/ForgotPassword';
import ResetPassword from '../views/ResetPassword';
import SignedOut from '../views/SignedOut';
import AdminDashboard from '../views/AdminDashboard';

import Main from '../views/Main';

const PrivateRoute = ({ children, ...rest }) => {
	let auth = useAuth();
	return (
		<Route
			{...rest}
			render={({ location }) =>
				auth.user ? (
					children
				) : (
					<Redirect
						to={{
							pathname: "/login",
							state: { from: location }
						}}
					/>
				)
			}
		/>
	);
}


const Routes = () => {
	return (
		<ProvideAuth>
			<BrowserRouter>
				<ProfileProvider>
					<ModalProvider>
						<Main>
							<ErrorBoundry>
								<Switch>
									<Route path="/login" component={Login} />
									<Route path="/forgot-password" component={ForgotPassword} />
									<Route path="/reset-password" component={ResetPassword} />
									<Route path="/signed-out" component={SignedOut} />
									<PrivateRoute>
										<Route exact path="/" component={Home} />
										<Route exact path="/admin" component={AdminDashboard} />
										<MechProvider>
											<Route exact path="/mech-landing" component={MechLanding} />
											<Route exact path="/choose-class" component={ChooseClass} />
											<Route exact path="/build-protocols" component={BuildProtocols} />
											<Route exact path="/build-protocols/:loadoutId" component={BuildProtocolsEdit} />
											<Route exact path="/play-sheet/:loadoutId" component={PlaySheet} />
											<Route exact path="/load" component={Load} />
											<Route exact path="/ark-unbound" component={ArkUnbound} />
											<Route exact path="/ark-unbound/:loadoutId" component={ArkUnbound} />
										</MechProvider>
										<Route exact path="/profile-landing" component={ProfileLanding} />
										<Route exact path="/origin-path/:currentStep" component={OriginPath} />
										<PilotProvider>
											<Route exact path="/pilot-landing" component={PilotLanding} />
											<Route exact path="/pilot-choose-class" component={PilotChooseClass} />
											<Route exact path="/pilot-load" component={PilotLoad} />
											<Route exact path="/pilot-build-protocols" component={PilotBuildProtocols} />
											<Route exact path="/pilot-build-protocols/:loadoutId" component={PilotBuildProtocolsEdit} />
											<Route exact path="/pilot-play-sheet/:loadoutId" component={PilotPlaySheet} />
										</PilotProvider>
									</PrivateRoute>
									<Route path="*" component={NotFound} />
								</Switch>
							</ErrorBoundry>
						</Main>
					</ModalProvider>
				</ProfileProvider>
			</BrowserRouter>
		</ProvideAuth>
	)
}

export default Routes;