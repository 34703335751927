/* eslint-disable no-unused-vars */
import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';

import {useUpdateModal} from '../../hooks/useModal';
import {useProfile, useProfileUpdate} from '../../hooks/useProfile';

import generatePilot from '../../utilities/generatePilot';
import DROPDOWNDATA from '../../data/dropDownData';

import Button from '../FormElements/Button';
import OriginPathRadio from '../FormElements/OriginPathRadio';
import InputWrap from '../FormElements/InputWrap';
import Dropdown from '../FormElements/Dropdown';

import './modal.scss';

const defaultOption = {
  skills: [],
  lore: [],
  contact: []
}

let optionOne = {...defaultOption};
let optionTwo = {...defaultOption};

const Modal = ({show, content, closeModal}) => {
  const updateModal = useUpdateModal();
  const [textValue, setTextValue] = useState('');
  const [skillValueOne, setSkillValueOne] = useState('');
  const [contactTextValueOne, setContactTextValueOne] = useState('');
  const [loreTextValueOne, setLoreTextValueOne] = useState('');

  const [skillValueTwo, setSkillValueTwo] = useState('');
  const [contactTextValueTwo, setContactTextValueTwo] = useState('');
  const [loreTextValueTwo, setLoreTextValueTwo] = useState('');
  const [showInput, setShowInput] = useState(false);
  const [advancedOptionOne, setAdvancedOptionOne] = useState('');
  const [advancedOptionTwo, setAdvancedOptionTwo] = useState('');
  let currentOption = {};

  const history = useHistory();
  const profile = useProfile();
  const updateProfile = useProfileUpdate();

  const textUpdate = (e) => {
    setTextValue(e.target.value);
  }

  const getElement = (type, options) => {
    currentOption = defaultOption;
    if (type === 'radio') {
      return options.map((option, index) => <OriginPathRadio required={true} key={index} name={type} value={option.name} onChange={() => {Object.assign(optionOne, option);}}>{option.label || option.name}</OriginPathRadio>);
    } else if (type === 'text') {
      Object.assign(currentOption, options[0]);
      return <InputWrap labelText="Hatred" required={true} inputName="hatred" placeholder="Who do you hate?" value={textValue} onChange={(e) => textUpdate(e)} />
    } else if (type === 'radioGroup') {
      return (
        options.map(option => {
        return (
          <div className="flex flex-col" key={option.groupName}>
          <div className="radioGroup">
            {
              option.group.map((o, i) => {
                return (
                  <OriginPathRadio required={true} key={i} name={option.groupName} value={o.name} onChange={(e, label = o.label, group = option.groupName) => {
                    if (group === 'one') {
                      Object.assign(optionOne, o);
                      label === 'hatred' ? setShowInput(true) : setShowInput(false);
                    } else {
                      Object.assign(optionTwo, o);
                    }
                  }
                  }>{o.label}</OriginPathRadio>
                )
              })
            }
          </div>
          { option.groupName === 'one' ? showInput && <InputWrap required={true} labelText="Hatred" inputName="hatred" placeholder="Who do you hate?" value={textValue} onChange={(e) => textUpdate(e)} /> : null }
          </div>
        )
      })
      )
    } else if (type === 'advanced') {
      const {home} = profile;
      const option = options[home];
      Object.assign(currentOption, option);
      // Determine what the pilot home is...
      if(home === 'Inner Haven') {
        // Display - Radio buttons for skills, contact and lore x2
        return (
          <div>
            <div className="flex flex-col">
              <div className="radioGroup row flex">
                <OriginPathRadio required={true} name="groupOne" value="skill" onChange={(e) => setAdvancedOptionOne(e.target.value)}>Skill</OriginPathRadio>
                <OriginPathRadio required={true} name="groupOne" value="contact" onChange={(e) => setAdvancedOptionOne(e.target.value)}>Contact</OriginPathRadio>
                <OriginPathRadio required={true} name="groupOne" value="lore" onChange={(e) => setAdvancedOptionOne(e.target.value)}>Lore</OriginPathRadio>
              </div>
              {advancedOptionOne && advancedOptionOne === 'skill' ?
              // onChange, options, value, inputName, labelText, initialOption, required
                <Dropdown required={true} options={DROPDOWNDATA.skillsList} value={skillValueOne} inputName="skillOne" labelText="Skill" onChange={e => setSkillValueOne(e.target.value)} /> : null
              }
              {advancedOptionOne && advancedOptionOne === 'contact' ?
                <InputWrap required={true} labelText="Contact" inputName="contactOne" placeholder="Add a contact" value={contactTextValueOne} onChange={(e) => setContactTextValueOne(e.target.value)} /> : null
              }
              {advancedOptionOne && advancedOptionOne === 'lore' ?
                <InputWrap required={true} labelText="Lore" inputName="loreOne" placeholder="Add some lore" value={loreTextValueOne} onChange={(e) => setLoreTextValueOne(e.target.value)} /> : null
              }
            </div>
            <div className="flex flex-col m-t-2">
              <div className="radioGroup row flex">
                <OriginPathRadio required={true} name="groupTwo" value="skill" onChange={(e) => setAdvancedOptionTwo(e.target.value)}>Skill</OriginPathRadio>
                <OriginPathRadio required={true} name="groupTwo" value="contact" onChange={(e) => setAdvancedOptionTwo(e.target.value)}>Contact</OriginPathRadio>
                <OriginPathRadio required={true} name="groupTwo" value="lore" onChange={(e) => setAdvancedOptionTwo(e.target.value)}>Lore</OriginPathRadio>
              </div>
              {advancedOptionTwo && advancedOptionTwo === 'skill' ?
              // onChange, options, value, inputName, labelText, initialOption, required
                <Dropdown required={true} options={DROPDOWNDATA.skillsList} value={skillValueTwo} inputName="skillTwo" labelText="Skill" onChange={e => setSkillValueTwo(e.target.value)} /> : null
              }
              {advancedOptionTwo && advancedOptionTwo === 'contact' ?
                <InputWrap required={true} labelText="Contact" inputName="contactTwo" placeholder="Add a contact" value={contactTextValueTwo} onChange={(e) => setContactTextValueTwo(e.target.value)} /> : null
              }
              {advancedOptionTwo && advancedOptionTwo === 'lore' ?
                <InputWrap required={true} labelText="Lore" inputName="loreTwo" placeholder="Add some lore" value={loreTextValueTwo} onChange={(e) => setLoreTextValueTwo(e.target.value)} /> : null
              }
            </div>
          </div>
        )

      } else if (home === 'Haven') {
        // Display - Radio buttons for skills, contact and lore x1
        // Display input for lore
        return (
          <div>
            <div className="flex flex-col">
              <InputWrap required={true} labelText="Lore" inputName="loreOne" placeholder="Add some lore" value={loreTextValueOne} onChange={(e) => setLoreTextValueOne(e.target.value)} />
            </div>
            <div className="flex flex-col m-t-2">
              <div className="radioGroup row flex">
                <OriginPathRadio required={true} name="groupTwo" value="skill" onChange={(e) => setAdvancedOptionTwo(e.target.value)}>Skill</OriginPathRadio>
                <OriginPathRadio required={true} name="groupTwo" value="contact" onChange={(e) => setAdvancedOptionTwo(e.target.value)}>Contact</OriginPathRadio>
                <OriginPathRadio required={true} name="groupTwo" value="lore" onChange={(e) => setAdvancedOptionTwo(e.target.value)}>Lore</OriginPathRadio>
              </div>
              {advancedOptionTwo && advancedOptionTwo === 'skill' ?
              // onChange, options, value, inputName, labelText, initialOption, required
                <Dropdown required={true} options={DROPDOWNDATA.skillsList} value={skillValueTwo} inputName="skillTwo" labelText="Skill" onChange={e => setSkillValueTwo(e.target.value)} /> : null
              }
              {advancedOptionTwo && advancedOptionTwo === 'contact' ?
                <InputWrap required={true} labelText="Contact" inputName="contactTwo" placeholder="Add a contact" value={contactTextValueTwo} onChange={(e) => setContactTextValueTwo(e.target.value)} /> : null
              }
              {advancedOptionTwo && advancedOptionTwo === 'lore' ?
                <InputWrap required={true} labelText="Lore" inputName="loreTwo" placeholder="Add some lore" value={loreTextValueTwo} onChange={(e) => setLoreTextValueTwo(e.target.value)} /> : null
              }
            </div>
          </div>
          )

      } else if (home === 'Cleave') {
        // Display - Radio buttons form skills, contact and lore x1
        // Display Radio buttons for Network or Socialize skills
        return (
          <div>
            <div className="flex flex-col">
              <div className="radioGroup">
                <OriginPathRadio required={true} name="skill" value="socialize" onChange={e => {setSkillValueOne(e.target.value)}}>Socialize</OriginPathRadio>
                <OriginPathRadio required={true} name="skill" value="networking" onChange={e => {setSkillValueOne(e.target.value)}}>Networking</OriginPathRadio>
              </div>
            </div>
            <div className="flex flex-col m-t-2">
              <div className="radioGroup row flex">
                <OriginPathRadio required={true} name="groupTwo" value="skill" onChange={(e) => setAdvancedOptionTwo(e.target.value)}>Skill</OriginPathRadio>
                <OriginPathRadio required={true} name="groupTwo" value="contact" onChange={(e) => setAdvancedOptionTwo(e.target.value)}>Contact</OriginPathRadio>
                <OriginPathRadio required={true} name="groupTwo" value="lore" onChange={(e) => setAdvancedOptionTwo(e.target.value)}>Lore</OriginPathRadio>
              </div>
              {advancedOptionTwo && advancedOptionTwo === 'skill' ?
              // onChange, options, value, inputName, labelText, initialOption, required
                <Dropdown required={true} options={DROPDOWNDATA.skillsList} value={skillValueTwo} inputName="skillTwo" labelText="Skill" onChange={e => setSkillValueTwo(e.target.value)} /> : null
              }
              {advancedOptionTwo && advancedOptionTwo === 'contact' ?
                <InputWrap required={true} labelText="Contact" inputName="contactTwo" placeholder="Add a contact" value={contactTextValueTwo} onChange={(e) => setContactTextValueTwo(e.target.value)} /> : null
              }
              {advancedOptionTwo && advancedOptionTwo === 'lore' ?
                <InputWrap required={true} labelText="Lore" inputName="loreTwo" placeholder="Add some lore" value={loreTextValueTwo} onChange={(e) => setLoreTextValueTwo(e.target.value)} /> : null
              }
            </div>
          </div>
          )
      }
    }
  }

  const cancel = () => {
    updateModal({show: false});
  }

  const confirm = () => {
    console.log('Confirm', optionOne, optionTwo);
    if(textValue !== '') {
      Object.assign(currentOption, {lore:[{key:'hatred', value: textValue}]});
    }

    if(skillValueOne) {
      optionOne.skills = [{[skillValueOne]: 1}]
    }

    if(skillValueTwo) {
      optionTwo.skills = [{[skillValueTwo]: 1}]
    }

    if(contactTextValueOne) {
      optionOne.contact = [{key: contactTextValueOne, value: 1}]
    }

    if(contactTextValueTwo) {
      optionTwo.contact = [{key: contactTextValueTwo, value: 1}]
    }

    if(loreTextValueOne) {
      optionOne.lore = [loreTextValueOne];
    }

    if(loreTextValueTwo) {
      optionTwo.lore = [loreTextValueTwo];
    }
    const skills = [...optionOne.skills, ...optionTwo.skills];
    const contact = [...optionOne.contact, ...optionTwo.contact];
    const lore = [...optionOne.lore, ...optionTwo.lore];

    console.log('options: ', optionOne, optionTwo);

    Object.assign(currentOption, optionOne, optionTwo, {skills: skills}, {contact: contact}, {lore: lore});

    const updatedPilot = generatePilot(currentOption, profile, currentOption.currentStep);
    updateProfile(oldState => ({...oldState, ...updatedPilot}));
    if(currentOption.next) {
      history.push(currentOption.next);
    }
    updateModal({show: false});
    setTextValue('');
    optionOne = defaultOption;
    optionTwo = defaultOption;
  }

  return show ? <div className="modalWrap flex align-center justify-center">
    <form onSubmit={() => confirm()} className="modal flex flex-col align-center">
      <h1>Make your choice.</h1>
      <div className="content flex">
        {content ?
        // Genareate the content here, based on type
          getElement(content.type, content.options)
          : <p>No content</p>
        }
      </div>
      <div className="footer">
        <Button className="sml secondary" onClick={() => cancel()}>Cancel</Button>
        <Button type="submit" className="sml primary">Confirm</Button>
      </div>
    </form>
  </div>
  : null
}

export default Modal