import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';

import {useAuth} from '../../utilities/auth';
import {db} from '../../services/firebase';

import Footer from '../../components/Footer';
import Protocol from '../../components/Protocol';



const PlaySheet = () => {

	const {loadoutId} = useParams();
	const auth = useAuth();
	const [currentLoadout, setCurrentLoadout] = useState({});
	const ref = `users/${auth.user.uid}/loadouts/${loadoutId}`

	useEffect(() => {
		let mounted = true;
		db.ref(ref).once('value')
			.then((snapshot) => {
				if(mounted) {
					setCurrentLoadout(snapshot.val());
				}
			});

			return() => mounted = false;
	}, [ref]);


	return (
		<>
			<main className="wrap">
				<h1>Make your moves</h1>
				<section className="flex align-start space-between playsheet">
					{currentLoadout?.protocols?.map((protocol, i) => <Protocol key={i} {...protocol} index={i} hideButton tag />)}
				</section>
			</main>
			<Footer />
		</>
	)
}

export default PlaySheet;