import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';

import calculateTotalCost from '../../utilities/costings';
import {useAuth} from '../../utilities/auth';
import {db} from '../../services/firebase';
import useSessionStorage from '../../utilities/useSessionStorage';
import DROPDOWNDATA from '../../data/dropDownData';
import AOEDATA from '../../data/aoeData';
import {ARKUNBOUND} from '../../data/mechData'
import generateRarityClass from '../../utilities/generateRarityClass';

import Footer from '../../components/Footer';
import Form from '../../components/FormElements/Form';
import InlineFields from '../../components/FormElements/InlineFields';
import InputWrap from '../../components/FormElements/InputWrap';
import Dropdown from '../../components/FormElements/Dropdown';
import FieldSet from '../../components/FormElements/FieldSet';
import Radio from '../../components/FormElements/Radio';
import Label from '../../components/FormElements/Label';
import ButtonWrap from '../../components/FormElements/ButtonWrap';
import Button from '../../components/FormElements/Button';
import Protocol from '../../components/Protocol';

import {ReactComponent as Chevrons} from '../../assets/chevrons.svg';


const initialFormState = {
	protocolName : '',
	budget: 55,
	move: 0,
	dice: 0,
	range: 0,
	damage: 0,
	statusSelf1: '',
	statusSelf2: '',
	statusSelf3: '',
	statusTarget1: '',
	statusTarget2: '',
	statusTarget3: '',
	aoe: 0,
	aoeSize: 1,
	knockback: 0,
	disengage: 0,
	costings: {
		cost: 0,
		actionPoints: 0
	}
}

const ArkUnbound = () => {
	let history =  useHistory();
	const auth = useAuth();
	const [currentLoadout, setCurrentLoadout] = useState(ARKUNBOUND);
	const [formData, setFormData] = useState(initialFormState);
	const [isEdit, setIsEdit] = useState(false);
	const [editingIndex, setEditingIndex] = useState(null);
	const [errorState, setErrorState] = useState({protocolName: false, loadoutName: false});

	const clearForm = () => {
		setFormData(initialFormState);
	};

	const formSubmit = (e, data) => {
		e.preventDefault();
		if(data.protocolName !== '') {
			if(formData.costings.cost <= formData.budget) {
				const protocols = currentLoadout.protocols;
				protocols.push(data);
				setCurrentLoadout(currentLoadout => ({...currentLoadout, protocols}));

				useSessionStorage.set('currentLoadout', currentLoadout);
				clearForm();
				setErrorState(errorState => ({...errorState, protocolName: false}));
			}
		} else {
			setErrorState(errorState => ({...errorState, protocolName: true}));
		}
	}

	const updateForm = (e) => {
		const name = e.target.name;
		let val = e.target.value;

		if(!isNaN(val) && val !== '') {
			val = parseInt(val, 10);
		}
		setFormData(formData => ({...formData, [name]: val}));
		setFormData(formData => ({...formData, costings: calculateTotalCost(formData)}));
	}

	const editProtocol = (e) => {
		e.preventDefault();
		const index = parseInt(e.target.dataset.index, 10);
		setFormData(currentLoadout.protocols[index]);
		setIsEdit(true);
		setEditingIndex(index);
	}

	const updateProtocol = () => {
		currentLoadout.protocols[editingIndex] = formData;
		setCurrentLoadout(currentLoadout => ({...currentLoadout}));
		setIsEdit(false);
		setEditingIndex(null);
		clearForm();
	}

	const handleContinueClick = () => {
		if (currentLoadout.loadoutName !== '') {
			if (currentLoadout?.protocols?.length > 0) {
				setErrorState(errorState => ({...errorState, loadoutName: false}));
				// Push current loadout to DB
				const ref = db.ref(`users/${auth.user.uid}/loadouts`).push();
				const key = ref.key;
				db.ref(`users/${auth.user.uid}/loadouts/${key}`).set({...currentLoadout, id: key}).then(() => {
					history.push(`/play-sheet/${key}`);
				});
			}
		} else {
			setErrorState(errorState => ({...errorState, loadoutName: true}));
		}
	}

	const setLoadoutName = (e) => {
		let val = e.target.value;
		setCurrentLoadout(currentLoadout => ({...currentLoadout, loadoutName: val}));
	}
	
	return (
		<>
		<main className="wrap">
			<h1>Build your protocols</h1>
			<section className="flex align-start space-between wrap_m">
				<Form className={`rarity rarity-${generateRarityClass(formData.costings.cost, formData.budget)}`} onSubmit={e => {formSubmit(e, formData)}}>
					<h1>{formData.costings.actionPoints}<sup>ap</sup></h1>
					<h2 className="budget">Cr: {formData.costings.cost} <span> / </span> {formData.budget}</h2>
					<InlineFields>
						<InputWrap inputName="protocolName" labelText="Protocol Name:" required value={formData.protocolName} error={errorState.protocolName} onChange={(e) => updateForm(e)} />
						<Dropdown labelText="budget:" inputName="budget" options={DROPDOWNDATA.budget} value={formData.budget} onChange={(e) => {updateForm(e)}} />
					</InlineFields>
					<InlineFields>
						<Dropdown labelText="Move First:" inputName="move" options={DROPDOWNDATA.move} value={formData.move} onChange={(e) => updateForm(e)} />
						<Dropdown labelText="Dice:" inputName="dice" options={DROPDOWNDATA.dice} value={formData.dice} onChange={(e) => updateForm(e)} />
						<Dropdown labelText="Range:" inputName="range" options={DROPDOWNDATA.rng} value={formData.range} onChange={e => updateForm(e)} />
						<Dropdown labelText="Damage:" inputName="damage" options={DROPDOWNDATA.dmg} value={formData.damage} onChange={e => updateForm(e)} />
					</InlineFields>
					<InlineFields className="space-between">
						<FieldSet legendText="Apply Status (Self)">
							<InputWrap labelText="Status Self-1" inputName="statusSelf1" placeholder="Status Self-1" value={formData.statusSelf1} onChange={e => updateForm(e)} />
							<InputWrap labelText="Status Self-2" inputName="statusSelf2" placeholder="Status Self-2" value={formData.statusSelf2} onChange={e => updateForm(e)} />
							<InputWrap labelText="Status Self-3" inputName="statusSelf3" placeholder="Status Self-3" value={formData.statusSelf3} onChange={e => updateForm(e)} />
						</FieldSet>

						<FieldSet legendText="Apply Status (Target)">
							<InputWrap labelText="Status Target-1" inputName="statusTarget1" placeholder="Status Target-1" value={formData.statusTarget1} onChange={e => updateForm(e)} />
							<InputWrap labelText="Status Target-2" inputName="statusTarget2" placeholder="Status Target-2" value={formData.statusTarget2} onChange={e => updateForm(e)} />
							<InputWrap labelText="Status Target-3" inputName="statusTarget3" placeholder="Status Target-3" value={formData.statusTarget3} onChange={e => updateForm(e)} />
						</FieldSet>
					</InlineFields>
					<InlineFields className="flex-col">
						<Label inputName="demo" labelText="AoE Type:" />
						<div className="aoeWrap">
						{AOEDATA.map((effect, i) => {
							return <Radio key={i} groupName="aoe" inputName={effect.type} value={effect.id} onChange={e => updateForm(e)} checked={formData.aoe === i} />
						})}
						</div>
					</InlineFields>
					<InputWrap className="half-width" labelText={`AoE Size: ${formData.aoeSize}`}  inputType="range" min="1" max="5" step="1" inputName="aoeSize" value={formData.aoeSize} onChange={e => updateForm(e)} />
					<InlineFields>
						{formData.dice !== 0 ?
						<Dropdown labelText="Knock-back:" inputName="knockback" options={DROPDOWNDATA.knockback} value={formData.knockback} onChange={e => updateForm(e)} />
						: null}
						<Dropdown labelText="Disengage:" inputName="disengage" options={DROPDOWNDATA.disengage} value={formData.disengage} onChange={e => updateForm(e)} />
					</InlineFields>
					<ButtonWrap>
						<Button className="secondary" type="reset" onClick={clearForm}>Cancel</Button>
						{!isEdit && currentLoadout.protocols.length <= 4 ?
							<Button className="primary fancy" type="submit">
								<span>Install</span>
								<span><Chevrons /></span>
							</Button>
						:
						<Button className="primary fancy" onClick={updateProtocol}>
							<span>Update</span>
							<span><Chevrons /></span>
						</Button>
						}
					</ButtonWrap>
				</Form>
				<div className="build-protocols flex flex-col">
					<InputWrap className="half-width" inputName="loadoutName" labelText="Loadout Name:" required value={currentLoadout.loadoutName} error={errorState.loadoutName} onChange={e => setLoadoutName(e)} />

					{currentLoadout?.protocols.map((protocol, i) => {
						return <Protocol key={i} {...protocol} index={i} onClick={editProtocol}  />
					})}
				</div>
			</section>
		</main>
		<Footer>
			<Button className="primary fancy" onClick={e => {handleContinueClick(e)}}>
				<span>Launch</span>
				<span><Chevrons /></span>
			</Button>
		</Footer>
		</>
	)
}

export default ArkUnbound;