import React from 'react';
import Label from '../Label';

import './dropdown.scss';

const Dropdown = ({onChange, options, value, inputName, labelText, initialOption, required}) => {
  return (
    <div className="inputWrap flex flex-col">
      <Label htmlFor={inputName} labelText={labelText} required={required} />
      <select name={inputName} className="dropdown" onChange={onChange} value={value}>
        {initialOption && <option value={initialOption.value}>{initialOption.text}</option>}
        {
          options.map((opt, i) => {
            return (
              <option key={i} value={opt.value}>{opt.text}</option>
            )
          })
        }
      </select>
    </div>
  )
}

export default Dropdown;