import React from 'react';
import {useParams, useHistory} from 'react-router-dom';

import {useProfileUpdate, useProfile} from '../../hooks/useProfile';
import {useUpdateModal} from '../../hooks/useModal';

import generatePilot from '../../utilities/generatePilot';

import Hexagon from '../Hexagon';

import './hexagonWrap.scss';

const HexagonWrap = ({data}) => {

  const {currentStep} = useParams();
  const history = useHistory();
  const updateProfile = useProfileUpdate();
  const profile = useProfile();
  const updateModal = useUpdateModal();

  const handleClick = (data) => {
    if(!profile.career) {
      if(!data.choice) {
        const updatedPilot = generatePilot(data, profile, currentStep);
        updateProfile(oldState => ({...oldState, ...updatedPilot}));
    
        if(data.next) {
          history.push(data.next);
        }
      } else {
        // Open modal or something
        updateModal({show: true, content: data.choice});
      }
    }
  }

  return (
    <>
      <ul className="hex-grid_list">
        {data && data.map((d, i) => {
          return <Hexagon key={i} data={d} hexClick={() => handleClick(d)} />
        })
        }
      </ul>
    </>
  )
};

export default HexagonWrap;