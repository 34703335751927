import React from 'react';

import {ReactComponent as Cross} from '../../assets/AoETypes/cross.svg';
import {ReactComponent as Lance} from '../../assets/AoETypes/lance.svg';
import {ReactComponent as MultiTargetTwo} from '../../assets/AoETypes/multiTarget-2.svg';
import {ReactComponent as MultiTargetThree} from '../../assets/AoETypes/multiTarget-3.svg';
import {ReactComponent as MultiTargetFour} from '../../assets/AoETypes/multiTarget-4.svg';
import {ReactComponent as None} from '../../assets/AoETypes/none.svg';
import {ReactComponent as Spread} from '../../assets/AoETypes/spread.svg';
import {ReactComponent as StarBurst} from '../../assets/AoETypes/starBurst.svg';

import './aoeicon.scss';

const AoeIcon = ({iconName}) => {
  let Icon = null;

  switch (iconName) {
    case 'cross':
      Icon = Cross;
      break;
    case 'lance':
      Icon = Lance;
      break;
    case 'multiTarget-2':
      Icon = MultiTargetTwo;
      break;
    case 'multiTarget-3':
      Icon = MultiTargetThree;
      break;
    case 'multiTarget-4':
      Icon = MultiTargetFour;
      break;
    case 'spread':
      Icon = Spread;
      break;
    case 'starBurst':
      Icon = StarBurst;
      break;
    default:
      Icon = None;
  }
  return (<Icon />);
}

export default AoeIcon;