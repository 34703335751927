import React from 'react';

import './hexagon.scss';

const Hexagon = ({data, hexClick}) => {  

  const {name} = data 
  return (
    <li className="hex-grid_item" onClick={hexClick} >
      <div className="hex-grid_content">
        {name !== '' ? <h2>{name}</h2> : null}
      </div>
    </li>
  )
};

export default Hexagon;