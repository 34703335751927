import {createContext, useContext, useState} from 'react';
import profileData from '../data/profileData';

const ProfileContext = createContext();
const UpdateProfileContext = createContext();
const PD = profileData;

// Allows us to use the pilotSkills state anywhere.
export function useProfile() {
  return useContext(ProfileContext);
}

// Allows us to update the pilotSkills state anywhere.
export function useProfileUpdate() {
  return useContext(UpdateProfileContext);
}

export function ProfileProvider({children}) {
  const [profile, setProfile] = useState(PD);

  return (
    <ProfileContext.Provider value={profile}>
      <UpdateProfileContext.Provider value={setProfile}>
        {children}
      </UpdateProfileContext.Provider>
    </ProfileContext.Provider>
  )
}
