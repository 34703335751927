const skills = {
  alert: 0,
  assess: 0,
  conviction: 0,
  evasive: 0,
  hardy: 0,
  hatred: 0,
  inspire: 0,
  intimidation: 0,
  repair: 0,
  survival: 0,
  firearms: 0,
  melee: 0,
  craft: 0,
  etiquette: 0,
  medicine: 0,
  negotiate: 0,
  networking: 0,
  presence: 0,
  resourceful: 0,
  reputation: 0,
  socialize: 0,
  streetwise: 0,
  wealth: 0,
  zoology: 0
}

const originPath = {
  home: null,
  birthRight: null,
  travails: null,
  motivation: null,
  career: null
};

const options = { ...originPath, home: [0, 1, 2, 3, 4, 5] };

const lore = [];

const contacts = [];

const pilotData = { ...originPath, name: null, skills, lore, contacts, options, skillPoints: 11, created: false };

export default pilotData;
