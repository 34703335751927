import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useAuth } from '../../utilities/auth';
import { db } from '../../services/firebase';


import { useProfile } from '../../hooks/useProfile';

import HexagonWrap from '../../components/HexagonWrap';
import OriginPathNav from '../../components/OriginPathNav';
import Button from '../../components/FormElements/Button';
import Jumbotron from '../../components/Jumbotron';

import { ReactComponent as Chevrons } from '../../assets/chevrons.svg';

// Origin Path Data
import originPath from '../../data/originPath';

const OriginPath = () => {
  const { currentStep } = useParams();
  const profile = useProfile();
  const auth = useAuth();
  const history = useHistory();

  const hasProfileData = (data) => {
    return data.career
  }

  const handleContinueClick = () => {
    if (profile.career) {
      // save data to DB and local storage
      profile.created = true;
      delete profile.options;
      db.ref(`users/${auth.user.uid}/pilot`).set(profile).then(() => {
        // route to Pilot Landing
        history.push('/profile-landing');
      }).catch(error => console.log('error: ', error));
    }
  }

  const getOriginOptions = () => {
    const options = profile.options[currentStep].map(o => originPath[currentStep][o]);
    return options
  }

  return (
    <>
      <main className="wrap">
        <h1 className="m-b-2">Origin Path</h1>
        {
          hasProfileData(profile) ?
            <Jumbotron title="Origin path complete.">
              <p>Continue, to discover yourself.</p>
              <Button className="m-t-2 primary fancy" onClick={() => handleContinueClick()}>
                <span>Continue</span>
                <span><Chevrons /></span>
              </Button>
            </Jumbotron> :
            <section className="flex align-start">
              <OriginPathNav data={originPath} currentStep={currentStep} />
              <HexagonWrap data={getOriginOptions()} />
            </section>
        }
      </main>
    </>
  )
};

export default OriginPath;