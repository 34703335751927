import React from 'react';

import './form.scss';

const Form = ({onSubmit, className = '', children}) => {
  return (
    <form className={`flex flex-col ${className}`} onSubmit={onSubmit} noValidate>
      {children}
    </form>
  )
}

export default Form;