import React from 'react';
import {useHistory} from 'react-router-dom';

import LinkCard from '../../components/LinkCard';
import Footer from '../../components/Footer';
import Button from '../../components/FormElements/Button';

import {useMechUpdate} from '../../hooks/useCurrentMech';
import MECHDATA from '../../data/mechData';

const ChooseClass = () => {
	const history = useHistory();
	const updateMech = useMechUpdate();

	return (
	<>
		<main className="wrap">
			<h1>Choose your class</h1>
			<section className="choose-class">
				{MECHDATA.map((mech, i) => {
					return (
					<LinkCard key={i} className="gridded">
						{mech.img && <img src={mech.img} alt={mech.name} className="img" />}
						<h2>{mech.name}</h2>
						<details>
							<summary>Learn more</summary>
							{mech.description}
						</details>
						<div className="buffsDebuffs flex space-between">
							<ul className="buffs">
								{mech.buffs.map((buff, i) => {
									return (
										<li key={i}>{buff.text}</li>
									)
								})}
							</ul>
							<ul className="debuffs">
								{mech.affects.map((debuff, i) => {
									return(
										<li key={i}>{debuff.text}</li>
									)
								})}
							</ul>
							<Button className="primary" onClick={() => {updateMech(mech); history.push('/build-protocols')}}>Choose</Button>
						</div>
					</LinkCard>)
				})}
			</section>
		</main>
		<Footer />
	</>
	)
}

export default ChooseClass;