import React from 'react';

import './inlineFields.scss';

const InlineFields = ({className = "", children}) => {
  return (<div className={`inlineField flex wrap_m ${className}`}>
    {children}
  </div>)
}

export default InlineFields;