import React from 'react';

import './button.scss';

const Button = ({type = 'button', onClick, className, children, ...rest}) => {
  return (
    <button className={`btn ${className}`} type={type} onClick={onClick} {...rest}>
      {children}
    </button>
)
}

export default Button