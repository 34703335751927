import React, {useEffect, useState} from 'react';
import { useHistory } from 'react-router-dom';
import {db} from '../services/firebase';

import LinkCard from '../components/LinkCard';
import NewsFeed from '../components/NewsFeed';
import LinkList from '../components/LinkList';
import NewsForm from '../components/NewsFeed/NewsForm';

const convertNews = (data) => {
	const output = [];
	if(data) {
		const keys = Object.keys(data);
		keys.map(key => output.push(data[key]));
		return output.reverse();
	}
}

const Home = () => {
	let history = useHistory();

	const [newsFeed, setNewsFeed] = useState();
  const newsRef = '/newsFeed';

	const deleteItem = (id) => {
		db.ref(`${newsRef}/${id}`).set(null).then(() => {
			db.ref(newsRef).once('value')
				.then((snapshot) => {
					setNewsFeed(snapshot.val());
				}
			)
		});
	}

	const updateNews = () => {
		db.ref(newsRef).once('value')
			.then((snapshot) => {
				setNewsFeed(snapshot.val());
			}
		)
	}

  useEffect(() => {
		db.ref(newsRef).once('value')
			.then((snapshot) => {
				setNewsFeed(snapshot.val());
			});
	}, [newsRef]);

	return (
		<main className="wrap">
			<h1>Welcome, Admin!</h1>
			<section className="grid three-cols gap-1">
				<div className='col-1'>
					<NewsForm updateNews={updateNews} />
				</div>
				<div className="col-2">
					<NewsFeed news={convertNews(newsFeed)} deleteItem={deleteItem} isAdminView={true} />
				</div>
				<div className="col-3">
					<LinkList isAdmin={true} />
					<LinkCard onClick={() => {history.push('/ark-unbound')}}>
						<h2>A.R.K. Unbound</h2>
					</LinkCard>
				</div>
			</section>
		</main>
	)
}

export default Home;