import React, {useEffect, useState} from 'react';
import { useHistory } from 'react-router-dom';

import LinkCard from '../../components/LinkCard';
import Footer from '../../components/Footer';

import useSessionStorage from '../../utilities/useSessionStorage';
import {useAuth} from '../../utilities/auth';
import {db} from '../../services/firebase';


const PilotLanding = () => {
	let history = useHistory();
	const [loadouts, setLoadouts] = useState([]);
	const auth = useAuth();

	const ref = `users/${auth.user.uid}/pilotLoadouts`

	useEffect(() => {
		let mounted = true;
		db.ref(ref).on('value', (snapshot) => {
			if(mounted) {
				setLoadouts(snapshot.val());
				useSessionStorage.set('pilotLoadouts', snapshot.val());
			}
		});
		return() => mounted = false;
	}, [ref]);

	return (
		<>
			<main className="wrap">
				<h1>Welcome, Pilot!</h1>
				<section className="flex align-center space-around wrap_m">
					{loadouts ?
					<LinkCard onClick={() => {history.push('/pilot-load')}}>
						<h2>Load</h2>
					</LinkCard>
					: null}
					<LinkCard onClick={() => {history.push('/pilot-choose-class')}}>
						<h2>Create</h2>
					</LinkCard>
				</section>
			</main>
			<Footer/>
		</>
	)
}

export default PilotLanding;