import React, {useState, useEffect} from 'react';

import Label from '../Label';

import './textArea.scss';

const InputWrap = ({labelText, inputName, onChange, value, className = "", required, labelSuffix, error, ...rest}) => {

  const [errorState, setErrorState] = useState(error);

  const handleBlur = (e) => {
    if(required) {
      if(e.target.value === '') {
        setErrorState(true);
      } else {
        setErrorState(false);
      }
    }
  }

  useEffect(() => {
    setErrorState(error);
    if(error) {
      window.scrollTo(
        {
          top: 0,
          left: 0,
          behavior: 'smooth'
        }
      );
    }
  }, [error]);

  return (
  <div className={`inputWrap flex flex-col ${className} ${errorState ? 'error': ''}`}>
    <Label htmlFor={inputName} labelText={labelText} labelSuffix={labelSuffix} required={required} />
    <textarea name={inputName} id={inputName} onBlur={handleBlur} onChange={onChange} value={value} required={required} {...rest} />
  </div>
  )
}

export default InputWrap;

