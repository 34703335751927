import React from 'react';
import {Link} from 'react-router-dom';

import './buttonLink.scss';

const ButtonLink = ({className, to, children, ...rest}) => {
  return (
    <Link to={to} className={`btn ${className}`} {...rest}>
      {children}
    </Link>
)
}

export default ButtonLink