import React from 'react';

import {useModal} from '../hooks/useModal';

import Header from '../components/Header';
import Modal from '../components/Modal';

const Main = ({children}) => {
  const modal = useModal()
 
  return (
    <>
      <Header />
      {children}
      <Modal show={modal.show} content={modal.content} />
    </>
  )
}

export default Main;