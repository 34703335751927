import React, {useState, useEffect} from 'react';
import {useHistory, Link} from 'react-router-dom';

import Form from '../components/FormElements/Form';
import InputWrap from '../components/FormElements/InputWrap';
import Button from '../components/FormElements/Button';
import ButtonWrap from '../components/FormElements/ButtonWrap';
import UserMessage from '../components/UserMessage';

import {ReactComponent as Chevrons} from '../assets/chevrons.svg';
import {useAuth} from '../utilities/auth';
import {db} from '../services/firebase';

const Login = () => {
	const [credentials, setCredentials] = useState({email: '', password: ''});
	const [errorState, setErrorState] = useState({email: false, password: false});
	const [signInError, setSignInError] = useState(false);
	const auth = useAuth();
	const history = useHistory();

	const updateForm = (e) => {
		const name = e.target.name;
		let val = e.target.value;

		setCredentials(credentials => ({...credentials, [name]: val}));
	}

	useEffect(() => {
    if(auth.user) {
			history.push('/');
		}
  }, [auth, history]);

	
	const formSubmit = (e) => {
		e.preventDefault();
		if(credentials.email !== '' && credentials.password !== '') {
			setErrorState(() => ({email: false, paswword: false}));
			setSignInError(false);
			auth.signin(credentials.email, credentials.password).then(res => {
				const ref = `users/${res.$.W}/isAdmin`;
				db.ref(ref).on('value', (snapshot) => {
					if(snapshot.val()) {
						history.push('/admin');
					} else {
						history.push('/');
					}
				})
			}).catch(error => {
				setSignInError(true);
			})
		} else {
			if(credentials.email === '') {
				setErrorState(errorState => ({...errorState, email: true}));
			} else {
				setErrorState(errorState => ({...errorState, password: true}));
			}
		}
	}

	return (
		<>
			<main className="wrap">
				<h1>Enter your credentials</h1>
				<section className="flex flex-col align-start">
					{signInError && <UserMessage type="error"><p>Username or password incorrect. Try again.</p></UserMessage>}
					<Form className="flex login" onSubmit={(e) => formSubmit(e)}>
						<InputWrap inputName="email" labelText="Email:" type="email" required value={credentials.email} error={errorState.email} onChange={(e) => updateForm(e)} />
						<InputWrap inputName="password" labelText="Password:" type="password" required value={credentials.password} error={errorState.password} onChange={(e) => updateForm(e)} />
						<ButtonWrap>
							<Link to='/forgot-password'>Forgot password?</Link>
							<Button className="primary fancy" type="submit">
								<span>Login</span>
								<span><Chevrons /></span>
							</Button>
						</ButtonWrap>
					</Form>
					</section>
			</main>
		</>
	)
}

export default Login;