import React from "react";

import Button from '../FormElements/Button';

import './newsItem.scss';

const getInitials = (name) => {
  const nameSplit = name.split(' ');
  const first = nameSplit[0].split('')[0];
  const last = nameSplit[nameSplit.length -1] ? nameSplit[nameSplit.length -1].split('')[0] : ''
  return `${first}${last}`;
}


const NewsItem = ({id, author, title, content, publishedDate, color, deleteItem, isAdminView}) => {
  return (
    <div className="newsItem">
      <div className="header">
        <span className={`avatar bg-${color || 'basic'}`}>{getInitials(author)}</span>
        <h3>{author} -</h3>
        <span className="pubDate">{publishedDate}</span>
      </div>
      <h4>{title}</h4>
      <p>{content}</p>
      {isAdminView ?
        <Button className="negative sml" onClick={() => deleteItem(id)}>Delete</Button>
        : null
      }
    </div>
  )
}

export default NewsItem;