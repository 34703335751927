import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';

import {usePilot} from '../../../hooks/useCurrentPilot';
import {useAuth} from '../../../utilities/auth';
import calculateTotalCost from '../../../utilities/costings';
import {db} from '../../../services/firebase';

import {INITIAL_FORM_STATE as initialFormState}  from '../../../constants';

import Footer from '../../../components/Footer';
import InputWrap from '../../../components/FormElements/InputWrap';
import Button from '../../../components/FormElements/Button';
import Protocol from '../../../components/Protocol';
import ProtocolForm from './components/ProtocolForm';

import {ReactComponent as Chevrons} from '../../../assets/chevrons.svg';

const BuildProtocols = () => {
	let history =  useHistory();
	const selectedPilot = usePilot();
	const auth = useAuth();
	const [currentLoadout, setCurrentLoadout] = useState({protocols: [], pilot: selectedPilot.name, loadoutName: ''});
	const [formData, setFormData] = useState(initialFormState);
	const [isEdit, setIsEdit] = useState(false);
	const [editingIndex, setEditingIndex] = useState(null);
	const [errorState, setErrorState] = useState({protocolName: false, loadoutName: false});

	const clearForm = () => {
		setFormData(initialFormState);
	};

	const formSubmit = (e, data) => {
		e.preventDefault();
		if(data.protocolName !== '') {
			if(formData.costings.cost <= formData.budget) {
				const protocols = currentLoadout.protocols;
				protocols.push(data);
				setCurrentLoadout(currentLoadout => ({...currentLoadout, protocols}));

				clearForm();
				setErrorState(errorState => ({...errorState, protocolName: false}));
			}
		} else {
			setErrorState(errorState => ({...errorState, protocolName: true}));
		}
	}

	const updateForm = (e) => {
		const name = e.target.name;
		let val = e.target.value;

		if(!isNaN(val) && val !== '') {
			val = parseInt(val, 10);
		}
		setFormData(formData => ({...formData, [name]: val}));
		setFormData(formData => ({...formData, costings: calculateTotalCost(formData)}));
	}

	const editProtocol = (e) => {
		e.preventDefault();
		const index = parseInt(e.target.dataset.index, 10);
		setFormData(currentLoadout.protocols[index]);
		setIsEdit(true);
		setEditingIndex(index);
	}

	const updateProtocol = () => {
		currentLoadout.protocols[editingIndex] = formData;
		setCurrentLoadout(currentLoadout => ({...currentLoadout}));
		setIsEdit(false);
		setEditingIndex(null);
		clearForm();
	}

	const setLoadoutName = (e) => {
		let val = e.target.value;
		setCurrentLoadout(currentLoadout => ({...currentLoadout, loadoutName: val}));
	}

	const handleContinueClick = () => {
			if (currentLoadout.loadoutName !== '') {
				if (currentLoadout?.protocols?.length > 0) {
					setErrorState(errorState => ({...errorState, loadoutName: false}));
					// Push current loadout to DB
					const ref = db.ref(`users/${auth.user.uid}/pilotLoadouts`).push();
					const key = ref.key;
					db.ref(`users/${auth.user.uid}/pilotLoadouts/${key}`).set({...currentLoadout, id: key}).then(() => {
						history.push(`/pilot-play-sheet/${key}`);
					});
				}
			} else {
				setErrorState(errorState => ({...errorState, loadoutName: true}));
			}
	}
	return (
		<>
		<main className="wrap">
			<h1>Build your protocols</h1>
			<h2>Class: {selectedPilot.name}</h2>
			<section className="flex align-start space-between wrap_m">
				<ProtocolForm
					formData={formData}
					selectedPilot={selectedPilot}
					formSubmit={formSubmit}
					updateForm={updateForm}
					clearForm={clearForm}
					errorState={errorState}
					isEdit={isEdit}
					updateProtocol={updateProtocol}
					currentLoadout={currentLoadout}
				/>
				<div className="build-protocols flex flex-col">
					<InputWrap className="half-width" inputName="loadoutName" labelText="Loadout Name:" required value={currentLoadout.loadoutName} error={errorState.loadoutName} onChange={e => setLoadoutName(e)} />

					{currentLoadout.protocols && currentLoadout.protocols.map((protocol, i) => {
						return <Protocol key={i} {...protocol} index={i} onClick={editProtocol}  />
					})}
				</div>
			</section>
		</main>
		<Footer>
			<Button className="primary fancy" onClick={() => handleContinueClick()}>
				<span>Launch</span>
				<span><Chevrons /></span>
			</Button>
		</Footer>
		</>
	)
}

export default BuildProtocols;