import React from 'react';
import {Link} from 'react-router-dom'; 

const SignedOut = () => {
	return (
	<>
		<main className="wrap">
			<h1>Signed out sucessfully</h1>
      <section className="flex align-center justify-center">
        <Link to="/login">Sign back in?</Link>
      </section>
		</main>
	</>
	)
}

export default SignedOut;