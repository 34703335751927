import React, {useEffect, useRef, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';

import Button from '../FormElements/Button';
import {ReactComponent as Logo} from '../../assets/logo.svg';

import {useAuth} from '../../utilities/auth';

import './header.scss';

const Header = ({isLoggedIn}) => {
	const [scrolled, setScrolled] = useState(false);
  const ref = useRef(null);
  const history = useHistory();
  const auth = useAuth();

  const handleScroll = () => {
    if (ref.current) {
      setScrolled(window.scrollY > 0);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, []);

  const signOut = (e) => {
    e.preventDefault();
    auth.signout(() => history.push('/signed-out'))
  }

  const isAdmin = () => {
    return auth.user.$.W === 'zMtImI3u3wWUrCJyzLahmpvKxEz2' || auth.user.$.W === 'dypZ4dwpjlYQapkb9KBiCnXwnGG3'
  }


  return (
		<header ref={ref} className={scrolled ? 'scrolled' : ''}>
			<div className="wrap flex align-center">
				<Link to="/">
					<Logo />
				</Link>
				<h1>Midas Rift</h1>
        {isAdmin ? 
          <Link className='adminLink' to="/admin">Admin Dashboard</Link> :
          null
        }
        {auth.user !== false ?
        <Button className="secondary sml" onClick={e => signOut(e)}>Sign Out</Button> :
        null}
			</div>
		</header>
	)
}

export default Header