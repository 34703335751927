import React, {useState} from "react";

import {db} from '../../services/firebase';

import Form from '../FormElements/Form';
import InputWrap from '../FormElements/InputWrap';
import Dropdown from '../FormElements/Dropdown';
import ButtonWrap from "../FormElements/ButtonWrap";
import Button from "../FormElements/Button";
import TextArea from "../FormElements/TextArea";

import {ReactComponent as Chevrons} from '../../assets/chevrons.svg';

const initialFormState = {
  author: '',
  publishedDate: '',
  content: '',
  title: '',
  color: 'legendary',
  id: '',
  createdDate: null
}

const colors = [
  {text: 'gold', value:'legendary'},
  {text: 'purple', value:'expert'},
  {text: 'azure', value:'advanced'},
  {text: 'emerald', value:'intermediate'},
  {text: 'navy', value:'basic'}
]

const NewsForm = ({updateNews}) => {
  const [formData, setFormData] = useState(initialFormState);

  const updateForm = (e) => {
    const name = e.target.name;
    let val = e.target.value;
    setFormData(formData => ({...formData, [name]: val}));
  }

  const clearForm = () => {
    setFormData(initialFormState);
  };

  const formSubmit = (e) => {
    e.preventDefault();
    // Push current loadout to DB
    const ref = db.ref(`/newsFeed`).push();
    const key = ref.key;
    db.ref(`/newsFeed/${key}`).set({...formData, id: key, createdDate: new Date().getTime()}).then(() => {
      clearForm();
      updateNews();
    });
  }

  return (
    <Form>
      <h2 className="h3 text-center m-b-2">Propaganda Machine</h2>
      <InputWrap inputName="author" labelText="Author:" required value={formData.author} onChange={(e) => updateForm(e)} />
      <InputWrap inputName="title" labelText="Title:" required value={formData.title} onChange={(e) => updateForm(e)} />
      <TextArea inputName="content" labelText="Content:" required value={formData.content} onChange={(e) => updateForm(e)} />
      <InputWrap inputName="publishedDate" labelText="Publish Date:" required value={formData.publishedDate} onChange={(e) => updateForm(e)} />
      <Dropdown labelText="Color:" inputName="color" options={colors} value={formData.color} onChange={e => updateForm(e)} />
      <ButtonWrap>
        <Button className="secondary" type="reset" onClick={clearForm}>Cancel</Button>
        <Button className="primary fancy" onClick={(e) => formSubmit(e)}>
          <span>Save</span>
          <span><Chevrons /></span>
        </Button>
      </ButtonWrap>
    </Form>
  )
}

export default NewsForm;