import React from 'react';

import Form from '../../../../components/FormElements/Form';
import InlineFields from '../../../../components/FormElements/InlineFields';
import InputWrap from '../../../../components/FormElements/InputWrap';
import Dropdown from '../../../../components/FormElements/Dropdown';
import FieldSet from '../../../../components/FormElements/FieldSet';
import Radio from '../../../../components/FormElements/Radio';
import Label from '../../../../components/FormElements/Label';
import ButtonWrap from '../../../../components/FormElements/ButtonWrap';
import Button from '../../../../components/FormElements/Button';

import AOEDATA from '../../../../data/aoeData';
import generateRarityClass from '../../../../utilities/generateRarityClass';
import DROPDOWNDATA from '../../../../data/dropDownData';

import {ReactComponent as Chevrons} from '../../../../assets/chevrons.svg';

const ProtocolForm = ({formData, selectedPilot, formSubmit, updateForm, clearForm, errorState, isEdit, updateProtocol, currentLoadout}) => {

  return (
    <Form className={`rarity rarity-${generateRarityClass(formData.costings.cost, formData.budget)}`} onSubmit={e => {formSubmit(e, formData)}}>
      <h1>{formData.costings.actionPoints}<sup>ap</sup></h1>
      <h2 className="budget">Cr: {formData.costings.cost} <span> / </span> {formData.budget}</h2>
      <InlineFields>
        <InputWrap inputName="protocolName" labelText="Protocol Name:" required value={formData.protocolName} error={errorState.protocolName} onChange={(e) => updateForm(e)} />
        <Dropdown labelText="budget:" inputName="budget" options={DROPDOWNDATA.budget} value={formData.budget} onChange={(e) => {updateForm(e)}} />
      </InlineFields>
      <InlineFields>
        <Dropdown labelText="Move First:" inputName="move" options={DROPDOWNDATA.move} value={formData.move} onChange={(e) => updateForm(e)} />
        <Dropdown labelText="Dice:" inputName="dice" options={DROPDOWNDATA.dice} value={formData.dice} onChange={(e) => updateForm(e)} />
        <Dropdown labelText="Range:" inputName="range" options={DROPDOWNDATA.rng} value={formData.range} onChange={e => updateForm(e)} />
      </InlineFields>
      <Dropdown labelText="Damage:" inputName="damage" options={DROPDOWNDATA.pilotDmg} value={formData.damage} onChange={e => updateForm(e)} />
      <InlineFields className="space-between">
        <FieldSet legendText="Apply Status (Self)">
          <Dropdown labelText="Status Self-1" inputName="statusSelf1" options={selectedPilot.buffs} initialOption={{text:'none', value:0}} value={formData.statusSelf1} onChange={e => updateForm(e)} />
          <Dropdown labelText="Status Self-2" inputName="statusSelf2" options={selectedPilot.buffs} initialOption={{text:'none', value:0}} value={formData.statusSelf2} onChange={e => updateForm(e)} />
          <Dropdown labelText="Status Self-3" inputName="statusSelf3" options={selectedPilot.buffs} initialOption={{text:'none', value:0}} value={formData.statusSelf3} onChange={e => updateForm(e)} />
        </FieldSet>

        <FieldSet legendText="Apply Status (Target)">
          <Dropdown labelText="Status Target-1" inputName="statusTarget1" options={selectedPilot.affects} initialOption={{text:'none', value:0}} value={formData.statusTarget1} onChange={e => updateForm(e)} />
          <Dropdown labelText="Status Target-2" inputName="statusTarget2" options={selectedPilot.affects} initialOption={{text:'none', value:0}} value={formData.statusTarget2} onChange={e => updateForm(e)} />
          <Dropdown labelText="Status Target-3" inputName="statusTarget3" options={selectedPilot.affects} initialOption={{text:'none', value:0}} value={formData.statusTarget3} onChange={e => updateForm(e)} />
        </FieldSet>
      </InlineFields>
      <InlineFields className="flex-col">
        <Label inputName="demo" labelText="AoE Type:" />
        <div className="aoeWrap">
        {AOEDATA.map((effect, i) => {
          return <Radio key={i} groupName="aoe" inputName={effect.type} value={effect.id} onChange={e => updateForm(e)} checked={formData.aoe === i} />
        })}
        </div>
      </InlineFields>
      <InputWrap className="half-width" labelText={`AoE Size: ${formData.aoeSize}`}  inputType="range" min="1" max="5" step="1" inputName="aoeSize" value={formData.aoeSize} onChange={e => updateForm(e)} />
      <InlineFields>
        {formData.dice !== 0 ?
        <Dropdown labelText="Knock-back:" inputName="knockback" options={DROPDOWNDATA.knockback} value={formData.knockback} onChange={e => updateForm(e)} />
        : null}
        <Dropdown labelText="Disengage:" inputName="disengage" options={DROPDOWNDATA.disengage} value={formData.disengage} onChange={e => updateForm(e)} />
      </InlineFields>
      <ButtonWrap>
        <Button className="secondary" type="reset" onClick={clearForm}>Cancel</Button>
        {!isEdit && currentLoadout?.protocols?.length <= 4 ?
          <Button className="primary fancy" type="submit">
            <span>Install</span>
            <span><Chevrons /></span>
          </Button>
        :
        <Button className="primary fancy" onClick={updateProtocol}>
          <span>Update</span>
          <span><Chevrons /></span>
        </Button>
        }
      </ButtonWrap>
    </Form>
  )
}

export default ProtocolForm;