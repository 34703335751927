const AOEDATA = [
  {type:'none', name:'None', value:1, id: 0},
  {type:'cross', name:'Cross', value:1.5, id: 1},
  {type:'spread', name:'Spread', value:1.5, id: 2},
  {type:'starBurst', name:'Star Burst', value:2.5, id: 3},
  {type:'lance', name:'Lance', value:0.5, id: 4},
  {type:'multiTarget-2', name:'Multi-target 2', value:1.5, id: 5},
  {type:'multiTarget-3', name:'Multi-target 3', value:2.25, id: 6},
  {type:'multiTarget-4', name:'Multi-target 4', value:3, id: 7}
]

export default AOEDATA;