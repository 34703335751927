const originPath = {
  home: [
    {
      name: 'Wilds',
      skills: [{ alert: 1 }, { medicine: 1 }, { repair: 1 }, { survival: 1 }],
      opens: [0, 1],
      next: 'birthRight'
    },
    {
      name: 'Wanderer',
      skills: [
        { alert: 1 },
        { assess: 1 },
        { socialize: 1 }
      ],
      contact: [
        {
          value: 1,
          key: 'Guildhall'
        }
      ],
      opens: [0, 1, 2],
      next: 'birthRight'
    },
    {
      name: 'Frontier',
      choice: {
        type: 'radio',
        options: [
          {
            name: 'Frontier town',
            currentStep: 'home',
            skills: [
              { negotiate: 1 },
              { socialize: 1 },
              { streetwise: 1 }
            ],
            contact: [
              {
                value: 1,
                key: 'Guildhall'
              }
            ],
            opens: [1, 2, 3],
            next: 'birthRight'
          },
          {
            name: 'Homestead',
            currentStep: 'home',
            skills: [
              { hardy: 1 },
              { resourceful: 1 }
            ],
            lore: [{
              value: 1,
              key: 'Zoology'
            }],
            contact: [
              {
                value: 1,
                key: 'Extended Family'
              }
            ],
            opens: [1, 2, 3],
            next: 'birthRight'
          },
          {
            name: 'Deadwood',
            currentStep: 'home',
            skills: [
              { intimidation: 1 },
              { melee: 1 },
              { resourceful: 1 },
            ],
            contact: [
              {
                value: 1,
                key: 'Mercenary Guild'
              }
            ],
            opens: [1, 2, 3],
            next: 'birthRight'
          }
        ]
      }
    },
    {
      name: 'Cleave',
      choice: {
        type: 'radioGroup',
        options: [
          {
            groupName: 'one',
            group: [
              { name: 'Cleave', label: 'repair', currentStep: 'home', skills: [{ resourceful: 1 }, { socialize: 1 }, { repair: 1 }], opens: [2, 3, 4], next: 'birthRight' },
              { name: 'Cleave', label: 'medicine', currentStep: 'home', skills: [{ resourceful: 1 }, { socialize: 1 }, { medicine: 1 }], opens: [2, 3, 4], next: 'birthRight' }
            ]
          },
          {
            groupName: 'two',
            group: [
              { name: 'Cleave', label: 'mentor', currentStep: 'home', contact: [{ key: 'Mentor', value: 1 }], opens: [2, 3, 4], next: 'birthRight' },
              { name: 'Cleave', label: 'gang', currentStep: 'home', contact: [{ key: 'Gang', value: 1 }], opens: [2, 3, 4], next: 'birthRight' }
            ]
          }
        ]
      },
    },
    {
      name: 'Haven',
      skills: [
        { negotiate: 1 },
        { wealth: 1 },
        { presence: 1 }
      ],
      contact: [
        {
          value: 1,
          key: 'Network'
        }
      ],
      opens: [3, 4, 5],
      next: 'birthRight'
    },
    {
      name: 'Inner Haven',
      skills: [
        { etiquette: 1 },
        { reputation: 1 },
        { wealth: 1 },
      ],
      contact: [
        {
          value: 1,
          key: 'CEO'
        }
      ],
      opens: [4, 5],
      next: 'birthRight'
    },
  ],
  birthRight: [
    {
      name: 'Survivor',
      skills: [{ evasive: 1 }, { hardy: 1 }],
      opens: [0, 1],
      next: 'travails'
    },
    {
      name: 'Scarbrand',
      skills: [{ firearms: 1 }, { streetwise: 1 }],
      opens: [0, 1, 2],
      next: 'travails'
    },
    {
      name: 'Journeyman',
      choice: {
        type: 'radio',
        options: [
          { name: 'Journeyman', label: 'Craft', currentStep: 'birthRight', skills: [{ craft: 1 }, { resourceful: 1 }], opens: [1, 2, 3], next: 'travails' },
          { name: 'Journeyman', label: 'Medicine', currentStep: 'birthRight', skills: [{ resourceful: 1 }, { medicine: 1 }], opens: [1, 2, 3], next: 'travails' },
          { name: 'Journeyman', label: 'Zoology', currentStep: 'birthRight', lore: [{ key: 'Zoology', value: 1 }], skills: [{ resourceful: 1 }], opens: [1, 2, 3], next: 'travails' }
        ]
      },
    },
    {
      name: 'Creed',
      choice: {
        type: 'radio',
        options: [
          {
            name: 'Creed', label: 'Conviction', currentStep: 'birthRight', skills: [{ conviction: 1 }],
            contact: [
              { key: 'Creed', value: 1 }
            ],
            opens: [2, 3, 4],
            next: 'travails'
          },
          {
            name: 'Creed', label: 'Inspire', currentStep: 'birthRight', skills: [{ inspire: 1 }],
            contact: [
              { key: 'Creed', value: 1 }
            ],
            opens: [2, 3, 4],
            next: 'travails'
          },
        ]
      }
    },
    {
      name: 'Prodigious',
      choice: {
        type: 'advanced',
        options: {
          'Inner Haven': {
            name: 'Prodigious', label: 'Inner Haven', currentStep: 'birthRight', opens: [3, 4, 5], next: 'travails'
          },
          'Haven': {
            name: 'Prodigious', label: 'Haven', currentStep: 'birthRight', opens: [3, 4, 5], next: 'travails'
          },
          'Cleave': {
            name: 'Prodigious', label: 'Cleave', currentStep: 'birthRight', opens: [3, 4, 5], next: 'travails'
          }
        }
      }
    },
    {
      name: 'Vaunted',
      skills: [{ inspire: 1 }, { wealth: 1 }],
      opens: [4, 5],
      next: 'travails'
    }
  ],
  travails: [
    {
      name: 'Hunted',
      desc: 'You must always act to help others who are being hunted by man or beast.',
      opens: [0, 1],
      next: 'motivation'
    },
    {
      name: 'Hearthless',
      desc: 'You must always act to prevent others from becoming homeless or the losing family.',
      opens: [0, 1, 2],
      next: 'motivation'
    },
    {
      name: 'Calamity',
      desc: 'You must always act to prevent a disaster you can predict.',
      opens: [1, 2, 3],
      next: 'motivation'
    },
    {
      name: 'Indebted',
      desc: 'You must always act to reduce the debt you owe.',
      opens: [2, 3, 4],
      next: 'motivation'
    },
    {
      name: 'Disgraced',
      desc: 'You must always act to improve your reputation with others.',
      opens: [3, 4, 5],
      next: 'motivation'
    },
    {
      name: 'Vendetta',
      desc: 'You must act to defend the honour or reputation of your allies.',
      opens: [4, 5],
      next: 'motivation'
    }
  ],
  motivation: [
    {
      name: 'Endurance',
      skills: [{ hardy: 1 }, { survival: 1 }],
      opens: [0, 1, 2],
      next: 'career'
    },
    {
      name: 'Fortune',
      skills: [{ networking: 1 }, { wealth: 1 }],
      opens: [1, 2, 3],
      next: 'career'
    },
    {
      name: 'Vengeance',
      choice: {
        type: 'text',
        options: [
          {
            name: 'Vengence',
            skills: [{ conviction: 1 }, { hatred: 1 }],
            lore: [{ key: 'hatred', value: '' }],
            opens: [2, 3, 4],
            next: 'career',
            currentStep: 'motivation'
          }
        ]
      },
    },
    {
      name: 'Renown',
      skills: [{ conviction: 1 }, { reputation: 1 }],
      opens: [3, 4, 5],
      next: 'career'
    },
    {
      name: 'Pride',
      skills: [{ inspire: 1 }, { presence: 1 }],
      opens: [4, 5, 6],
      next: 'career'
    },
    {
      name: 'Prestige',
      skills: [{ reputation: 1 }, { presence: 1 }],
      opens: [5, 6, 7],
      next: 'career'
    }
  ],
  career: [
    {
      name: 'Scavenger',
      skills: [{ alert: 1 }, { assess: 1 }],
      contact: [{ key: 'Fence', value: 1 }]
    },
    {
      name: 'Prospector',
      skills: [{ assess: 1 }, { evasive: 1 }, { negotiate: 1 }]
    },
    {
      name: 'Mercenary',
      choice: {
        type: 'radioGroup',
        options: [
          {
            groupName: 'one',
            group: [
              { name: 'Mercenary', label: 'Intimidation', currentStep: 'career', skills: [{ intimidation: 1 }], contact: [{ key: 'Mercenary Guild', value: 1 }] },
              { name: 'Mercenary', label: 'Hatred', currentStep: 'career', skills: [{ hatred: 1 }], contact: [{ key: 'Mercenary Guild', value: 1 }] }
            ]
          },
          {
            groupName: 'two',
            group: [
              { name: 'Mercenary', label: 'Melee', currentStep: 'career', skills: [{ melee: 1 }], contact: [{ key: 'Mercenary Guild', value: 1 }] },
              { name: 'Mercenary', label: 'Firearms', currentStep: 'career', skills: [{ firearms: 1 }], contact: [{ key: 'Mercenary Guild', value: 1 }] }
            ]
          }
        ]
      }
    },
    {
      name: 'Artisan',
      skills: [{ assess: 1 }, { craft: 1 }, { repair: 1 }],
      currentStep: 'career'
    },
    {
      name: 'Retainer',
      choice: {
        type: 'radio',
        options: [
          { name: 'Retainer', label: 'melee', currentStep: 'career', skills: [{ melee: 1 }, { intimidation: 1 }], contact: [{ key: 'Master', value: 1 }] },
          { name: 'Retainer', label: 'firearms', currentStep: 'career', skills: [{ firearms: 1 }, { intimidation: 1 }], contact: [{ key: 'Master', value: 1 }] }
        ]
      }
    },
    {
      name: 'Merchant',
      choice: {
        type: 'radio',
        options: [
          { name: 'Merchant', label: 'guildhall', currentStep: 'career', skills: [{ assess: 1 }, { negotiate: 1 }], contact: [{ key: 'Guildhall', value: 1 }] },
          { name: 'Merchant', label: 'network', currentStep: 'career', skills: [{ assess: 1 }, { negotiate: 1 }], contact: [{ key: 'Network', value: 1 }] }
        ]
      }
    },
    {
      name: 'Minister',
      skills: [{ etiquette: 1 }, { inspire: 1 }, { networking: 1 }],
      currentStep: 'career'
    },
    {
      name: 'Corporate Officer',
      skills: [{ conviction: 1 }, { inspire: 1 }],
      contact: [{
        key: 'CEO',
        value: 1
      }],
      currentStep: 'career'
    }
  ]
};

export default originPath;
