import React from 'react';

import {ReactComponent as ErrorIcon} from '../../assets/errorIcon.svg';
import {ReactComponent as SuccessIcon} from '../../assets/successIcon.svg';

import './userMessage.scss';

const Icon = ({type}) => {
  let Icon = null;

  switch (type) {
    case 'error':
      Icon = ErrorIcon;
      break;
    case 'success':
      Icon = SuccessIcon;
      break;
    default:
      Icon = ErrorIcon;
  }
  return (<Icon />);
}

const UserMessage = ({type = '', children}) => {
  return (
    <aside className={`flex align-center userMessage ${type}`}>
      <Icon type={type} />
      {children}
    </aside>
  )
}

export default UserMessage;