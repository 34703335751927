import React from 'react';

import './skillsList.scss';

const SkillsList = ({ skills }) => {

  return (
    <aside className="skillsList">
      <h2>SKills and abilites</h2>
      <ul>
        {Object.entries(skills).map(([key, value]) => {
          return <li className={`flex space-between align-center level-${value}`} key={key}>
            <span>{key}</span>
            <div className="indicators flex space-between align-center gap-1">
              <div className="indicatorWrap flex">
                <span className="indicator" />
                <span className="indicator" />
                <span className="indicator" />
                <span className="indicator" />
                <span className="indicator" />
              </div>
              <div className="progressIndicator">
                <span className="indicator" />
                <span className="indicator" />
                <span className="indicator" />
                <span className="indicator" />
                <span className="indicator" />
              </div>
            </div>
          </li>
        })}
      </ul>
    </aside>
  )
}

export default SkillsList;