import React, {useState} from 'react';

import Form from '../components/FormElements/Form';
import InputWrap from '../components/FormElements/InputWrap';
import Button from '../components/FormElements/Button';
import ButtonWrap from '../components/FormElements/ButtonWrap';
import UserMessage from '../components/UserMessage';

import {useAuth} from '../utilities/auth';

const ForgotPassword = () => {
	const [email, setEmail] = useState('');
	const [errorState, setErrorState] = useState(false);
	const [informUser, setInformUser] = useState({type: '', message: '', show: false});

	const auth = useAuth();

	const updateForm = (e) => {
		setEmail(e.target.value);
	}

	const formSubmit = (e) => {
		e.preventDefault();
		if(email !== '') {
			setErrorState(false);
			setInformUser(informUser => ({...informUser, show: false}));
			auth.sendPasswordResetEmail(email).then(res => {
				setInformUser({type: 'success', show: true, message: 'Reset email sent. Check your inbox.'});
			}).catch(() => {
				setInformUser({type: 'error', show: true, message: 'Unknown email address, try again.'});
			})
		} else {
				setErrorState(true);
		}
	}

	return (
		<>
			<main className="wrap">
				<h1>Enter your email address</h1>
				{informUser.show && <UserMessage type={informUser.type}><p>{informUser.message}</p></UserMessage>}
				<Form className="flex login" onSubmit={(e) => formSubmit(e)}>
					<InputWrap inputName="email" labelText="Email:" type="email" required value={email} error={errorState} onChange={(e) => updateForm(e)} />
					<ButtonWrap>
						<Button className="primary" type="submit">
							<span>Reset</span>
						</Button>
					</ButtonWrap>
				</Form>
			</main>
		</>
	)
}

export default ForgotPassword;