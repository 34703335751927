import React from 'react';

import  './originPathNav.scss';

const OriginPathNav = ({data, currentStep}) => {
  return (
    <nav className="originPath_nav">
      <ul>
        {Object.entries(data).map(([key]) => {
          return <li key={key} className={currentStep === key ? 'active' : ''}>{key}</li>
        })}
      </ul>
    </nav>
  )
}

export default OriginPathNav;