import React from 'react';
import {useHistory} from 'react-router-dom';
import Button from '../FormElements/Button';

import './footer.scss';

const Footer = ({to, hideButton, children}) => {
  let history =  useHistory();
  
  const handleClick = () => {
    if(!to) {
      history.goBack();
    } else {
      history.push(to);
    }
  }

  return (
    <footer>
      <div className="wrap">
        {!hideButton && <Button className="secondary"onClick={() => handleClick()}>Cancel</Button>}
        {children}
      </div>
    </footer>
  )
}

export default Footer;