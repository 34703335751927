import {AFFECTS, BUFFS} from '../constants';

const MENTOR = {
  name: 'Mentor',
  loadoutName: '',
  buffs: [{text:BUFFS[4], value:4}, {text:BUFFS[5], value:5}, {text:BUFFS[8], value:8}],
  affects: [{text:AFFECTS[2], value:2}, {text:AFFECTS[5], value:5}, {text:AFFECTS[6], value:6}],
  protocols: [],
  description: "The Mentors of Midas are those most suited to command. Often moving between different roles on the battlefield Mentors will be found directing their allies and guiding their efforts in coordinated strikes against the enemy. Their impeccable understanding of the flow of battle allows them to perceive weaknesses others would never notice and create devastating battle plans as the combat unfolds around them.",
  img: '/images/Mentor.jpg'
};

const REAPER = {
  name: 'Reaper',
  loadoutName: '',
  buffs: [{text:BUFFS[2], value:2}, {text:BUFFS[6], value:6}, {text:BUFFS[8], value:8}],
  affects: [{text:AFFECTS[1], value:1}, {text:AFFECTS[3], value:3}, {text:AFFECTS[4], value:4}],
  protocols: [],
  description: "The Reapers of Midas are those most suited to challenging their enemy in close quarters. Often at the forefront of any battle they will be found in the swirling melee delivering devastating strikes or overwhelming firepower to the heart of the enemy. Their prowess for close exchanges and fearless tactics allow them to maximise their damage output and strike decisively at critical targets.",
  img: '/images/Reaper.jpg'
};

const BASTION = {
  name: 'Bastion',
  loadoutName: '',
  buffs: [{text:BUFFS[3], value:3}, {text:BUFFS[5], value:5}, {text:BUFFS[7], value:7}],
  affects: [{text:AFFECTS[1], value:1}, {text:AFFECTS[2], value:2}, {text:AFFECTS[3], value:3}],
  protocols: [],
  description: "The Bastions of Midas are those most suited to enduring the onslaught of battle. Often the first to dive headlong into combat they will be found leading the charges into the heart of the foe, weathering blows that would kill their allies and protecting them from the worst attacks of the enemy. Their incredible resilience and dauntless attitude allow them to thrive where others would surely fall and hold the line against all odds.",
  img: '/images/Bastion.jpg'
};

const CALLER = {
  name: 'Caller',
  loadoutName: '',
  buffs: [{text:BUFFS[1], value:1}, {text:BUFFS[6], value:6}, {text:BUFFS[7], value:7}],
  affects: [{text:AFFECTS[4], value:4}, {text:AFFECTS[5], value:5}, {text:AFFECTS[6], value:6}],
  protocols: [],
  description: "The Callers of Midas are those most suited to raining death from afar. Often fighting from extreme ranges utilizing devastating munitions they will be found unleashing the incredible weapons of Midas to great destructive effect or delivering unerring sniper strikes to decimate the enemy. Their incredible weaponry and keen positioning allows them to cut through resistance without ever risking reprisals.",
  img: '/images/Caller.jpg'
};


const PILOTDATA = [MENTOR, REAPER, BASTION, CALLER];

export default PILOTDATA;