import {AFFECTS, BUFFS} from '../constants';

const M3 = {
  name: 'M3',
  loadoutName: '',
  buffs: [{text:BUFFS[4], value:4}, {text:BUFFS[5], value:5}, {text:BUFFS[8], value:8}],
  affects: [{text:AFFECTS[2], value:2}, {text:AFFECTS[5], value:5}, {text:AFFECTS[6], value:6}],
  protocols: [],
  description: "The M3 fulfils the roles of command and coordination on the battlefield, capable of turning even the direst situation to its ally's favour or turning a drawn-out battle into a decisive victory. The key ability of an M3 allows it to enhance and multiply the combat effectiveness of all its allies at the expense of not excelling at any one role itself. Pilots have found many ways to employ the M3's kit, from using its unique ability to co-pilot and enhance allied weapons to using their own systems to direct allies into optimum combat solutions. The M3 relies on the other classifications for the specialisation it itself lacks whilst making sure they excel at their given role.",
  img: '/images/M3.png'
};

const R3 = {
  name: 'R3',
  loadoutName: '',
  buffs: [{text:BUFFS[2], value:2}, {text:BUFFS[6], value:6}, {text:BUFFS[8], value:8}],
  affects: [{text:AFFECTS[1], value:1}, {text:AFFECTS[3], value:3}, {text:AFFECTS[4], value:4}],
  protocols: [],
  description: "The R3 fulfils the roles of close support and shock craft on the battlefield, capable of unleashing devastating amounts of damage at close range. The key ability of the R3 allows it to increase its own power levels and damage output at the cost of exposing their own critical locations to astute enemies. Pilots have found many unique ways to employ the R3's kit, from overwhelming application of melee strikes to single near perfect strikes capable of killing in a single blow. The R3 relies on the other classifications to bear the brunt of the damage and draw enemy fire whilst it positions itself for maximum damage output.",
  img: '/images/R3.png'
};

const B4 = {
  name: 'B4',
  loadoutName: '',
  buffs: [{text:BUFFS[3], value:3}, {text:BUFFS[5], value:5}, {text:BUFFS[7], value:7}],
  affects: [{text:AFFECTS[1], value:1}, {text:AFFECTS[2], value:2}, {text:AFFECTS[3], value:3}],
  protocols: [],
  description: "The B4 fulfils the roles of damage mitigation and frontline brawler on the battlefield. The key ability of the B4 allows it weather huge amounts of incoming damage at the cost of reducing its offensive capabilities. Pilots have found many ways to employ the B4's kit, from standing I open ground and defying the enemy to move it, to dismantling enemy defences whilst preparing to drop its own at the opportune moment to devastate the enemy. The B4 relies on the other classifications to bring the enemy to a swift end whilst offering them protection.",
  img: '/images/B4.png'
};

const C4 = {
  name: 'C4',
  loadoutName: '',
  buffs: [{text:BUFFS[1], value:1}, {text:BUFFS[6], value:6}, {text:BUFFS[7], value:7}],
  affects: [{text:AFFECTS[4], value:4}, {text:AFFECTS[5], value:5}, {text:AFFECTS[6], value:6}],
  protocols: [],
  description: "The C4 fulfils the roles of fire support and artillery on the battlefield, capable of fighting at extreme ranges and with devastating firepower. The key ability of the C4 allows it to sacrifice mobility in exchange for extending its reach far beyond the capabilities of the other classifications. Pilots have found many unique ways to employ the C4's kit, from precision application of lethal force like a sniper of old, to scorched earth area bombardments. The C4 relies on the other classifications to keep enemies at a distance whist it rains death from afar.",
  img: '/images/C4.png'
};

export const ARKUNBOUND = {
  name: 'ARK Unbound',
  loadoutName: '',
  buffs: [],
  affects: [],
  protocols: [],
}


const MECHDATA = [M3, R3, B4, C4];

export default MECHDATA;