import React, {useEffect, useState} from "react";
import {db} from '../../services/firebase';

import Form from '../FormElements/Form';
import InputWrap from '../FormElements/InputWrap';
import ButtonWrap from "../FormElements/ButtonWrap";
import Button from "../FormElements/Button";

import Card from '../Card';

import './linkList.scss';

const convertLinkList = (data) => {
	const output = [];
	if(data) {
		const keys = Object.keys(data);
		keys.map(key => output.push(data[key]));
		return output;
	}
}

const initialFormState = {
  text: '',
  url: '',
  id: ''
}

const LinkList = ({isAdmin}) => {
  const linksRef = '/links';
  const [linksList, setLinksList] = useState();
  const [formData, setFormData] = useState(initialFormState);
  const [errorState, setErrorState] = useState({text: false, url: false});

  useEffect(() => {
		db.ref(linksRef).once('value')
			.then((snapshot) => {
				setLinksList(snapshot.val());
			});
	}, [linksRef]);

  const clearForm = () => {
    setFormData(initialFormState);
  };

  const updateForm = (e) => {
    const name = e.target.name;
    let val = e.target.value;
    setFormData(formData => ({...formData, [name]: val}));
  }

  const updateLinks = () => {
		db.ref(linksRef).once('value')
			.then((snapshot) => {
				setLinksList(snapshot.val());
			}
		)
	}

  const deleteItem = (id) => {
    db.ref(`/${linksRef}/${id}`).set(null).then(() => {
      updateLinks();
    });
  }

  const formSubmit = (e) => {
    e.preventDefault();
    setErrorState(errorState => ({ text: false, url: false}));
    if(formData.text !== '' && formData.url !== '') {

      // Push current loadout to DB
      const ref = db.ref(`${linksRef}`).push();
      const key = ref.key;
      db.ref(`/${linksRef}/${key}`).set({...formData, id: key}).then(() => {
        clearForm();
        updateLinks();
      });
    } else {
      if (formData.text === '') {
        setErrorState(errorState => ({...errorState, text: true}));
      }

      if (formData.url === '') {
        setErrorState(errorState => ({...errorState, url: false}));
      }
    }
  }

  return (<Card className="p-t-3">
    <h2 className="h3 text-center m-b-2">Useful links</h2>
    <ul className="linkList">
    {linksList && convertLinkList(linksList).map((item) => <li className="flex gap-1 align-center" key={item.id}><a href={item.url} target="_blank" rel="noreferrer">{item.text}</a>{isAdmin ? <Button className="negative sml" onClick={() => deleteItem(item.id)}>Delete</Button> : null}</li>)}
    </ul>
    {isAdmin ?
      <Form>
        <InputWrap inputName="text" labelText="Link Text:" required value={formData.text} error={errorState.text} onChange={(e) => updateForm(e)} />
        <InputWrap inputName="url" labelText="Link URL:" required value={formData.url} error={errorState.url} onChange={(e) => updateForm(e)} />
        <ButtonWrap>
          <Button className="secondary sml" type="reset" onClick={clearForm}>Cancel</Button>
          <Button className="primary sml" onClick={(e) => formSubmit(e)}>Save</Button>
        </ButtonWrap>
      </Form>
    : null
    }
  </Card>)
}

export default LinkList;