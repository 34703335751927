import React, {useState} from 'react';
import {useLocation} from 'react-router-dom';

import Form from '../components/FormElements/Form';
import InputWrap from '../components/FormElements/InputWrap';
import Button from '../components/FormElements/Button';
import ButtonWrap from '../components/FormElements/ButtonWrap';
import UserMessage from '../components/UserMessage';

import {useAuth} from '../utilities/auth';

const ResetPassword = () => {
	const [formData, setFormData] = useState({password: '', confirmPassword: ''});
	const [errorState, setErrorState] = useState({password: false, confirmPassword: false});
	const [informUser, setInformUser] = useState({type: '', message: '', show: false});
	const auth = useAuth();

	const useQuery = () => {
		return new URLSearchParams(useLocation().search);
	}

	const query = useQuery();

	const updateForm = (e) => {
		const name = e.target.name;
		const val = e.target.value;
		setFormData(formData => ({...formData, [name]: val}));
	}

	const formSubmit = (e) => {
		e.preventDefault();
		if(formData.password !== '' && formData.confirmPassword !== '') {
			if(formData.password === formData.confirmPassword) {
				setErrorState({password: false, confirmPassword: false});
				setInformUser(informUser => ({...informUser, show: false}));

				auth.confirmPasswordReset(query.get("oobCode"), formData.password).then(res => {
					setInformUser({type: 'success', show: true, message: 'Password reset successfully.'});
				}).catch(() => {
					setInformUser({type: 'error', show: true, message: 'A server error occured. Try again later.'});
				})
			} else {
				setInformUser({type: 'error', show: true, message: 'Passwords must match, try again.'});
			}
		} else {
			if(formData.password === '') {
				setErrorState(errorState =>({...errorState, password: true}));
			} else {
				setErrorState(errorState =>({...errorState, confirmPassword: true}));
			}
		}
	}

	return (
		<>
			<main className="wrap">
				<h1>Enter your new password</h1>
				{informUser.show && <UserMessage type={informUser.type}><p>{informUser.message}</p></UserMessage>}
				<Form className="flex login" onSubmit={(e) => formSubmit(e)}>
					<InputWrap inputName="password" labelText="Password:" type="password" required value={formData.password} error={errorState.password} onChange={(e) => updateForm(e)} />
					<InputWrap inputName="confirmPassword" labelText="Confirm Password:" type="password" required value={formData.confirmPassword} error={errorState.confirmPassword} onChange={(e) => updateForm(e)} />
					<ButtonWrap>
						<Button className="primary" type="submit">
							<span>Submit</span>
						</Button>
					</ButtonWrap>
				</Form>
			</main>
		</>
	)
}

export default ResetPassword;