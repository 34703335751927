import React from 'react';

import './fieldSet.scss';

const FieldSet = ({legendText, children}) => {
  return (
    <fieldset className="flex flex-col">
      <legend>{legendText}</legend>
      {children}
    </fieldset>)
};

export default FieldSet;