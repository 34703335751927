import React, {useState} from 'react';
import {Link} from 'react-router-dom';

import Footer from '../../components/Footer';
import Loadout from '../../components/Loadout';
import Jumbotron from '../../components/Jumbotron';

import useSessionStorage from '../../utilities/useSessionStorage';
import {db} from '../../services/firebase';
import {useAuth} from '../../utilities/auth';

const convertLoadouts = (data) => {
	const output = [];
	if(data) {
		const keys = Object.keys(data);
		keys.map(key => output.push(data[key]));
		return output;
	}
}

const Load = () => {
	const [loadouts, setLoadouts] = useState(convertLoadouts(useSessionStorage.get('loadouts')));
	const auth = useAuth();

	const deleteLoadout = (e, i) => {
		e.preventDefault();
		db.ref(`users/${auth.user.uid}/loadouts/${loadouts[i].id}`).set(null)
		.then((err) => {
			if(!err) {
				const updatedLoadouts = [...loadouts];
				updatedLoadouts.splice(i,1);
				setLoadouts(updatedLoadouts);
				useSessionStorage.set('loadouts', updatedLoadouts);
			}
		})
	}

	return (
		<>
			<main className="wrap">
    		<h1>Choose your loadout</h1>
				<section className="flex flex-col align-start space-between wrap_m">
					{loadouts && loadouts.length ?
						loadouts.map((loadout, i) => <Loadout deleteLoadout={e => deleteLoadout(e, i)} {...loadout} key={i} />)
						: <Jumbotron title="You have no loadouts">
								<p>Why not <Link to="/choose-class">create one</Link>.</p>
							</Jumbotron>
					}
				</section>
			</main>
			<Footer />
		</>
	);
}

export default Load;