import React from 'react';

import './contactsList.scss';

const ContactsList = ({ contacts }) => {
  return (
    <>
      <h2>Contacts</h2>
      {contacts && <ul className="contactsList">
        {Object.entries(contacts).map(([key]) => {
          return <li className="flex align-center gap-2" key={key}>{contacts[key].key}: {contacts[key].value}</li>
        })}
      </ul>}
    </>
  )
}

export default ContactsList