import React from "react";

import './advertWrap.scss';

const ads = [
  'aetherprises', 'dare-to', 'nls', 'roamers-nest', 'trasko', 'trasko-2'
];

const generateAd = () => {
  return ads[Math.floor(Math.random() * (ads.length - 1))]
}

const AdvertWrap = () => {
  return (
    <div className="advertWrap flex">
      <img src={`/images/adverts/${generateAd()}.jpg`} alt="Advert" />
    </div>
  )
}

export default AdvertWrap;