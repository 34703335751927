import React from "react";

import Card from '../Card';
import NewsItem from "../NewsItem";

import './newsFeed.scss';

const NewsFeed = ({news, deleteItem, isAdminView}) => {
  return (
    <Card className="newsFeed-wrap" noFlex={true}>
      <h2 className="text-center m-b-2">Latest News</h2>
      {news && news.length ?
      <ul className="newsFeed">
        {news.map((item) => <li key={item.id}><NewsItem {...item} isAdminView={isAdminView} deleteItem={deleteItem} /></li>)}
      </ul>
      : "It's a slow news day"}
    </Card>
  )
}

export default NewsFeed;