import React from 'react';
import {useHistory} from 'react-router-dom';

import LinkCard from '../../components/LinkCard';
import Footer from '../../components/Footer';
import Button from '../../components/FormElements/Button';

import {usePilotUpdate} from '../../hooks/useCurrentPilot';
import PILOTDATA from '../../data/pilotData';

const PilotChooseClass = () => {
	const history = useHistory();
	const updatePilot = usePilotUpdate();

	return (
	<>
		<main className="wrap">
			<h1>Choose your class</h1>
			<section className="choose-class">
				{PILOTDATA.map((pilot, i) => {
					return (
					<LinkCard key={i} className="gridded">
						{pilot.img && <img src={pilot.img} alt={pilot.name} className="img" />}
						<h2>{pilot.name}</h2>
						<details>
							<summary>Learn more</summary>
							{pilot.description}
						</details>
						<div className="buffsDebuffs flex space-between">
							<ul className="buffs">
								{pilot.buffs.map((buff, i) => {
									return (
										<li key={i}>{buff.text}</li>
									)
								})}
							</ul>
							<ul className="debuffs">
								{pilot.affects.map((debuff, i) => {
									return(
										<li key={i}>{debuff.text}</li>
									)
								})}
							</ul>
							<Button className="primary" onClick={() => {updatePilot(pilot); history.push('/pilot-build-protocols')}}>Choose</Button>
						</div>
					</LinkCard>)
				})}
			</section>
		</main>
		<Footer />
	</>
	)
}

export default PilotChooseClass;