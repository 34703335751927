import {RARITY_SCORES} from '../constants';

const generateRarity = (cost, budget) => {
  let className = '';

  if(cost > budget) {
    return className = 'rarity rarity-error';
  }

  if(budget <= RARITY_SCORES.basicPlus) {
    className = 'basic';
  } else if ((budget > RARITY_SCORES.basicPlus) && (budget <= RARITY_SCORES.intermediatePlus)) {
    className = 'intermediate';
  } else if ((budget > RARITY_SCORES.intermediatePlus) && (budget <= RARITY_SCORES.advancedPlus)) {
    className = 'advanced';
  } else if ((budget > RARITY_SCORES.advancedPlus) && (budget <= RARITY_SCORES.expertPlus)) {
    className = 'expert';
  } else if ((budget > RARITY_SCORES.expertPlus) && (budget <= RARITY_SCORES.legendary)) {
    className = 'legendary';
  }

  return className;
}

export default generateRarity;