import React from 'react';

import { BUFFS, AFFECTS } from '../../constants/index';
import AOEDATA from '../../data/aoeData';
import generateRarityClass from '../../utilities/generateRarityClass';

import Card from '../Card';
import Button from '../FormElements/Button';
import AoeIcon from '../AoeIcon';
import AoeSize from '../AoeSize';
import Tag from '../Tag';

import './protocol.scss';

const calcDamage = (dmg) => {
  const isPilot = window.location.href.includes('pilot');
  if (isPilot) {
    return dmg * 1;
  }
  return dmg * 10;
}

const getAoeData = (id) => {
  const { name, type } = AOEDATA.filter(aoe => aoe.id === id)[0]
  return { name, type };
}

const modifyArray = (arr, key) => {
  let stepOne
  if (isNaN(arr[0]) && arr[0]) {
    stepOne = arr.filter(item => item !== '');
    return stepOne.join(' & ');
  } else {
    stepOne = arr.filter(item => item > 0);
    const stepTwo = stepOne.map(status => key[status]);
    return stepTwo.join(' & ');
  }
}

const attackProtocol = (move, dice, damage) => {
  let First, Second = ``;

  move > 0 ? First = `Move ${move} then attack ` : First = 'Attack';
  Second = `${dice} ${dice === 0 || dice > 1 ? 'times' : 'time'} dealing ${calcDamage(damage)} dmg on each success.`;

  return <p>{First} {Second}</p>;
}

const Protocol = ({
  protocolName,
  budget,
  move,
  dice,
  range,
  damage,
  statusSelf1,
  statusSelf2,
  statusSelf3,
  statusTarget1,
  statusTarget2,
  statusTarget3,
  aoe,
  aoeSize,
  knockback,
  disengage,
  costings,
  onClick,
  index,
  tag = false,
  hideButton
}) => {
  const statusTarget = modifyArray([statusTarget1, statusTarget2, statusTarget3], AFFECTS);
  const statusSelf = modifyArray([statusSelf1, statusSelf2, statusSelf3], BUFFS);

  const rarity = generateRarityClass(costings.cost, budget);
  const aoeData = getAoeData(aoe);

  return (
    <Card className={`protocol rarity rarity-${rarity}`}>
      <h1>{protocolName} {tag ? <Tag className={`${rarity}`}>{rarity}</Tag> : null}</h1>
      <p className="actionPoints">{costings.actionPoints}<span>ap</span></p>
      <p>{`Range: ${range > 0 ? range : 'Melee'}`}{aoe !== 0 ? `; AoE: ${aoeData.name}; ` : null} {aoe !== 0 ? `Size: ${aoeSize}` : null}.</p>

      {attackProtocol(move, dice, damage)}

      {statusTarget.length ?
        <p>Apply {statusTarget} on each success.</p>
        : null
      }
      {statusSelf.length ?
        <p>Gain {statusSelf}.</p>
        : null
      }

      {knockback ?
        <p>If you score a successful attack knock the target back {knockback} {knockback === 1 ? 'space' : 'spaces'}.</p>
        : null
      }
      {disengage ?
        <p className="six">Disengage {disengage} {disengage === 1 ? 'space' : 'spaces'}.</p>
        : null
      }

      <div className="footer">
        {aoe !== 0 ? <i title={aoeData.name}><AoeIcon iconName={aoeData.type} /></i> : null}
        {aoe !== 0 ? <i title={`Size: ${aoeSize}`}><AoeSize size={aoeSize} /></i> : null}
        {!hideButton ?
          <Button className="primary sml" onClick={(e) => { onClick(e) }} data-index={index}>Edit</Button>
          : null}
      </div>
    </Card>
  )
}

export default Protocol;