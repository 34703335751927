import {createContext, useContext, useState} from 'react';

const ModalContext = createContext();
const UpdateModalContext = createContext();

// Allows us to use the pilotSkills state anywhere.
export function useModal() {
  return useContext(ModalContext);
}

// Allows us to update the pilotSkills state anywhere.
export function useUpdateModal() {
  return useContext(UpdateModalContext);
}

export function ModalProvider({children}) {
  const [modal, setModal] = useState({});

  return (
    <ModalContext.Provider value={modal}>
      <UpdateModalContext.Provider value={setModal}>
        {children}
      </UpdateModalContext.Provider>
    </ModalContext.Provider>
  )
}
