import React from 'react';
import {Link} from 'react-router-dom';

import Tag from '../Tag';
import Button from '../FormElements/Button';
import RevealingMenu from '../RevealingMenu';

import generateRarityClass from '../../utilities/generateRarityClass';

import './loadout.scss';

const generateURL = (name) => {
  return name === 'ARK Unbound' ? '/ark-unbound/' : window.location.href.includes('pilot') ? '/pilot-build-protocols/' : '/build-protocols/';
};

const generateLaunchURL = () => {
  return window.location.href.includes('pilot') ? '/pilot-play-sheet/' : 'play-sheet/';
}

const Loadout = ({deleteLoadout, index, ...props}) => {

  const {loadoutName, mech, protocols, id, name} = props;

  return (
    <article className="loadout flex align-center_d space-between_d">
      <div>
        <h1>{loadoutName} : {name || mech}</h1>
        <ul className="flex align-center">
          {protocols.map((protocol, i) => {
            const rarity = generateRarityClass(protocol.costings.cost, protocol.budget)
            return <li key={i}><Tag className={`isVisible ${rarity}`}>{protocol.protocolName}</Tag></li>
          })}
        </ul>
      </div>
      <RevealingMenu>
        <Button className="negative sml" onClick={deleteLoadout}>Delete</Button>
        <Link className="btn secondary sml" to={`${generateURL(name || mech)}${id}`}>Edit</Link>
        <Link className="btn primary sml" to={`${generateLaunchURL()}${id}`}>Launch</Link>
      </RevealingMenu>
    </article>
  )
}

export default Loadout