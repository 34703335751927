import React from 'react';

const OriginPathRadio = ({name, value, onChange, required, children}) => {
  return (
    <label>
      <input type="radio" name={name} value={value} onChange={onChange} required={required} />
      {children}
    </label>
  )
}

export default OriginPathRadio;