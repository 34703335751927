export const RARITY_SCORES = {
  basic: 55,
  basicPlus: 120,
  intermediate: 210,
  intermediatePlus: 325,
  advanced: 465,
  advancedPlus: 630,
  expert: 820,
  expertPlus: 1035,
  legendary: 1275
}

export const BUFFS = ['None', 'Range Up', 'Power Up', 'Armour up', 'Command', 'Shield', 'Scope In', 'Locked on', 'Evade'];

export const AFFECTS = ['None', 'Cripple', 'Baffle', 'Shred Armour', 'Burn', 'Marked', 'Expose Weakness'];

export const INITIAL_FORM_STATE = {
	protocolName : '',
	budget: 55,
	move: 0,
	dice: 0,
	range: 0,
	damage: 0,
	statusSelf1: 0,
	statusSelf2: 0,
	statusSelf3: 0,
	statusTarget1: 0,
	statusTarget2: 0,
	statusTarget3: 0,
	aoe: 0,
	aoeSize: 1,
	knockback: 0,
	disengage: 0,
	costings: {
		cost: 0,
		actionPoints: 0
	}
};