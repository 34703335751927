import { RARITY_SCORES } from '../constants/index';

const DROPDOWNDATA = {
  move: [
    { text: '0', value: 0 },
    { text: '1', value: 1 },
    { text: '2', value: 2 },
    { text: '3', value: 3 },
    { text: '4', value: 4 },
    { text: '5', value: 5 },
    { text: '6', value: 6 },
    { text: '7', value: 7 },
    { text: '8', value: 8 },
    { text: '9', value: 9 },
    { text: '10', value: 10 }
  ],
  dmg: [
    { text: '0', value: 0 },
    { text: '10', value: 1 },
    { text: '20', value: 2 },
    { text: '30', value: 3 },
    { text: '40', value: 4 },
    { text: '50', value: 5 },
    { text: '60', value: 6 },
    { text: '70', value: 7 },
    { text: '80', value: 8 },
    { text: '90', value: 9 },
    { text: '100', value: 10 },
    { text: '110', value: 11 },
    { text: '120', value: 12 },
    { text: '130', value: 13 },
    { text: '140', value: 14 },
    { text: '150', value: 15 },
    { text: '160', value: 16 },
    { text: '170', value: 17 },
    { text: '180', value: 18 },
    { text: '190', value: 19 },
    { text: '200', value: 20 },
    { text: '210', value: 21 },
    { text: '220', value: 22 },
    { text: '230', value: 23 },
    { text: '240', value: 24 },
    { text: '250', value: 25 },
    { text: '260', value: 26 },
    { text: '270', value: 27 },
    { text: '280', value: 28 },
    { text: '290', value: 29 },
    { text: '300', value: 30 },
    { text: '310', value: 31 },
    { text: '320', value: 32 },
    { text: '330', value: 33 },
    { text: '340', value: 34 },
    { text: '350', value: 35 },
    { text: '360', value: 36 },
    { text: '370', value: 37 },
    { text: '380', value: 38 },
    { text: '390', value: 39 },
    { text: '400', value: 40 },
    { text: '410', value: 41 },
    { text: '420', value: 42 },
    { text: '430', value: 43 },
    { text: '440', value: 44 },
    { text: '450', value: 45 },
    { text: '460', value: 46 },
    { text: '470', value: 47 },
    { text: '480', value: 48 },
    { text: '490', value: 49 },
    { text: '500', value: 50 }
  ],
  rng: [
    { text: 'Melee', value: 0 },
    { text: '2', value: 2 },
    { text: '3', value: 3 },
    { text: '4', value: 4 },
    { text: '5', value: 5 },
    { text: '6', value: 6 }],
  dice: [
    { text: '0', value: 0 },
    { text: '1', value: 1 },
    { text: '2', value: 2 },
    { text: '3', value: 3 },
    { text: '4', value: 4 },
    { text: '5', value: 5 },
    { text: '6', value: 6 },
    { text: '7', value: 7 },
    { text: '8', value: 8 },
    { text: '9', value: 9 },
    { text: '10', value: 10 },
  ],
  knockback: [
    { text: 'no', value: 0 },
    { text: '1', value: 1 },
    { text: '2', value: 2 },
    { text: '3', value: 3 },
    { text: '4', value: 4 },
    { text: '5', value: 5 }],
  budget: [
    { text: 'basic', value: RARITY_SCORES.basic },
    { text: 'basic+', value: RARITY_SCORES.basicPlus },
    { text: 'intermediate', value: RARITY_SCORES.intermediate },
    { text: 'intermediate+', value: RARITY_SCORES.intermediatePlus },
    { text: 'advanced', value: RARITY_SCORES.advanced },
    { text: 'advanced+', value: RARITY_SCORES.advancedPlus },
    { text: 'expert', value: RARITY_SCORES.expert },
    { text: 'expert+', value: RARITY_SCORES.expertPlus },
    { text: 'legendary', value: RARITY_SCORES.legendary }
  ],
  disengage: [
    { text: '0', value: 0 },
    { text: '1', value: 1 },
    { text: '2', value: 2 },
    { text: '3', value: 3 },
    { text: '4', value: 4 },
    { text: '5', value: 5 }
  ],
  skillsList: [
    { text: 'Alert', value: 'alert' },
    { text: 'Assess', value: 'assess' },
    { text: 'Craft', value: 'craft' },
    { text: 'Etiquette', value: 'etiquette' },
    { text: 'Evasive', value: 'evasive' },
    { text: 'Firearms', value: 'firearms' },
    { text: 'Hardy', value: 'hardy' },
    { text: 'Hatred', value: 'hatred' },
    { text: 'Inspire', value: 'inspire' },
    { text: 'Intimidation', value: 'intimidation' },
    { text: 'Medicine', value: 'medicine' },
    { text: 'Melee', value: 'melee' },
    { text: 'Negotiate', value: 'negotiate' },
    { text: 'Networking', value: 'networking' },
    { text: 'Presence', value: 'presence' },
    { text: 'Repair', value: 'repair' },
    { text: 'Reputation', value: 'reputation' },
    { text: 'Resourceful', value: 'resourceful' },
    { text: 'Socialize', value: 'socialize' },
    { text: 'Streetwise', value: 'streetwise' },
    { text: 'Survival', value: 'survival' },
    { text: 'Zoology', value: 'zoology' }
  ],
  pilotDmg: [
    { text: '0', value: 0 },
    { text: '1', value: 1 },
    { text: '2', value: 2 },
    { text: '3', value: 3 },
    { text: '4', value: 4 },
    { text: '5', value: 5 },
    { text: '6', value: 6 },
    { text: '7', value: 7 },
    { text: '8', value: 8 },
    { text: '9', value: 9 },
    { text: '10', value: 10 },
    { text: '11', value: 11 },
    { text: '12', value: 12 },
    { text: '13', value: 13 },
    { text: '14', value: 14 },
    { text: '15', value: 15 },
    { text: '16', value: 16 },
    { text: '17', value: 17 },
    { text: '18', value: 18 },
    { text: '19', value: 19 },
    { text: '20', value: 20 },
    { text: '21', value: 21 },
    { text: '22', value: 22 },
    { text: '23', value: 23 },
    { text: '24', value: 24 },
    { text: '25', value: 25 },
    { text: '26', value: 26 },
    { text: '27', value: 27 },
    { text: '28', value: 28 },
    { text: '29', value: 29 },
    { text: '30', value: 30 },
    { text: '31', value: 31 },
    { text: '32', value: 32 },
    { text: '33', value: 33 },
    { text: '34', value: 34 },
    { text: '35', value: 35 },
    { text: '36', value: 36 },
    { text: '37', value: 37 },
    { text: '38', value: 38 },
    { text: '39', value: 39 },
    { text: '40', value: 40 },
    { text: '41', value: 41 },
    { text: '42', value: 42 },
    { text: '43', value: 43 },
    { text: '44', value: 44 },
    { text: '45', value: 45 },
    { text: '46', value: 46 },
    { text: '47', value: 47 },
    { text: '48', value: 48 },
    { text: '49', value: 49 },
    { text: '50', value: 50 }
  ]
}

export default DROPDOWNDATA;