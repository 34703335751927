import React from 'react';

const NotFound = () => {
	return (
	<>
		<main className="wrap">
			<h1>404: Mech not found</h1>
		</main>
	</>
	)
}

export default NotFound;